
function CheckLogin(){
    let us = sessionStorage.getItem('logovan-id');
    if(!us || us == 0)
        us = localStorage.getItem('logovan-id');
    if(!us) return 0;
    return us;
}

class Korpa{
    constructor(){
        let k = localStorage.getItem('korpa');
        if(k){
            this.lista = JSON.parse(k);
           ;//console.log('KORPA: ', this.lista);
        }else{
            this.lista = [];
        }
    }
    obrisiKorpu(){
        this.lista = [];
        this.snimiSession();
    }
    snimiSession(){
        localStorage.setItem('korpa',JSON.stringify(this.lista));
       ;//console.log('KORPAAAAAAA', this.lista)
    }
    postojiProizvod(id){
        return this.lista.find(pr => (pr.id === id));
    }
    dodajProizvod(p, kol, dodaci){
        let pr = this.lista.find(pr => (pr.id === p.Id));
        if(pr){
            pr.kol = kol;
            pr.dodaci = dodaci;
        }else{
            this.lista.push({id:p.Id, naziv:p.Naziv, cena:p.Cena, kol:kol, dodaci:dodaci});
        }
        this.snimiSession();
    }
    obrisiProizvod(id){
        //console.log('lista pre', this.lista)
        
        this.lista = this.lista.filter(pr => (pr.id != id));
        //console.log('lista posle', this.lista)
        this.snimiSession();
    }
    promeniKolicinu(id, kol){
        let pr = this.lista.find(pr => (pr.id == id));
        pr.kol = kol;
        this.snimiSession();
    }
    izracunajCenu(id){
        let pr = this.lista.find(pr => (pr.id == id));
        let ukupno = (pr.cena)? parseFloat(pr.cena) : 0;
        if(pr.dodaci !== '')
            for(let deo in pr.dodaci){
                if(pr.dodaci[deo].cena && pr.dodaci[deo].cena != "0"){
                    ukupno += parseFloat(pr.dodaci[deo].cena);
                }
            }
        return ukupno;
    }
    ukupnaCena(){
        let u = this.lista.reduce((s,pr)=> (s+this.izracunajCenu(pr.id) * pr.kol), 0 );
        return u;
    }
}
export { CheckLogin, Korpa };