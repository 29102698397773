import React, { Component } from 'react';
import { ajaxCall, promiseAjaxCall } from './ajaxCall.js';
import ModalWindow from './BigImg/ModalWindow';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { podaci: [], greska: '', cekZapamti:false,
        modalContent:null, modalCloseFunction:'modalClose', modalBtns:'', modalTitle:null };
        this.login = this.login.bind(this);
        this.proveriRezultat = this.proveriRezultat.bind(this);
        this.promeniCekZapamti = this.promeniCekZapamti.bind(this);
        this.zaboravljenaLozinka = this.zaboravljenaLozinka.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.refEmail = React.createRef();
        this.refPass = React.createRef();
        this.refShow = React.createRef();
    }

    componentDidMount() {
        this.refEmail.current.focus();
        this.refShow.current.classList.add('hc-element-visible');
    }

    modalClose(){
        this.setState({ modalContent:null,    modalCloseFunction:'modalClose',  modalBtns:'', modalTitle:null });
    }

    zaboravljenaLozinka(e){
        e.preventDefault();
        let email = this.refEmail.current.value.trim();
        if(email === ''){
            //alert('Niste upisali mail!');
            this.setState({modalContent:'Niste upisali mail!' });
            return;
        }
        (async()=>{
            let resp = await promiseAjaxCall('mail.php', 'POST', {email:email, sta:'lozinka' });
            if(resp && resp.OKERR == true){
                //alert('Poslali smo vam mail sa zaboravljenom lozinkom.');
                this.setState({modalContent:'Poslali smo vam mail sa zaboravljenom lozinkom.' });

            }else{
                this.setState({modalContent:'Nemamo registrovan taj mail u bazi! Kontaktirajte nas ako želite da vam otvorimo nalog za online naručivanje.' });
                //alert('Nemamo registrovan taj mail u bazi! Kontaktirajte nas ako želite da vam otvorimo nalog za online naručivanje.');
            }
        })();
    }
    promeniCekZapamti(event){
        this.setState({cekZapamti:event.target.checked});
    }
    login(event){
        //event.preventDefault();
        let email = this.refEmail.current.value;
        let pass = this.refPass.current.value;
        ajaxCall('jinxapi/model/users/table', 'GET', {
			whereCols:[
                { colName: 'users_field_email', colVal: email, 
                    oper:'=', logicOper: 'AND'  },
                { colName: 'users_field_password', colVal: pass, 
                    oper:'=', logicOper: 'AND'  },
                { colName: 'users_field_potvrdjen', colVal: 1, 
                    oper:'=', logicOper: 'AND'  }
            ]

		}, this.proveriRezultat);
    }
    proveriRezultat(resp){
        if(resp){
            if(resp.OKERR == false){
                //alert('Pogresan user i/ili password!');
                this.setState({modalContent:'Pogresan user i/ili password!' });
            }else{
                //alert(resp.lines[0].id);
                if(this.state.cekZapamti === false)
                    sessionStorage.setItem('logovan-id', resp.lines[0].id);
                else
                    localStorage.setItem('logovan-id', resp.lines[0].id);
                let id = this.props.match.params.proizvodid;
                if(id)
                    window.location.href = "/proizvod/"+id;
                else
                    window.location.href = "/";
            }
        }else{
            //alert('!resp');
            this.setState({modalContent:'!resp' });
        }
    }


    render() {

        return (
            <div>
                <ModalWindow onClose={this[this.state.modalCloseFunction]} btns={this.state.modalBtns} title={this.state.modalTitle}>
                    {this.state.modalContent} 
                </ModalWindow>
                <div className="main-container login">

                <br />

                <br />

                <div className="container">

                <div className="row justify-content-center">

                    <div className="col-auto">

                    <div className="main hc-element-invisible" ref={this.refShow}  data-animation-effect="fadeIn" data-effect-delay="100">

                        <div className="form-block p-30 bg-light">

                        <br />

                        <h2 className="text-center display-4 prijavljivanje">Prijavljivanje</h2>

                        <br />

                        <div className="form-horizontal">

                            <div className="form-group has-feedback row">

                            <label htmlFor="inputUserName" className="col-md-3 text-md-right control-label col-form-label">Email</label>

                            <div className="col-md-8">

                                <input type="text" className="form-control" id="inputUserName" ref={this.refEmail} 
                                onKeyUp={(e)=>{if(e.keyCode === 13) this.refPass.current.focus();}} required />

                                <i className="fa fa-user form-control-feedback pr-4"></i>

                            </div>

                            </div>

                            <div className="form-group has-feedback row">

                            <label htmlFor="inputPassword" className="col-md-3 text-md-right control-label col-form-label">Lozinka</label>

                            <div className="col-md-8">

                                <input type="password" className="form-control" id="inputPassword" ref={this.refPass} required onKeyUp={(e)=>{if(e.keyCode === 13) this.login();}} />

                                <i className="fa fa-lock form-control-feedback pr-4"></i>

                            </div>

                            </div>

                            <div className="form-group row">

                            <div className="ml-md-auto col-md-9">

                                <div className="checkbox form-check">

                                <input className="form-check-input" type="checkbox" required onChange={this.promeniCekZapamti} 
                                    checked={this.state.cekZapamti} />

                                <label className="form-check-label">
                                    Zapamti me.

                                </label>

                                </div>

                                <button type="submit" className="btn btn-group btn-default btn-animated" onClick={this.login}>Log In <i className="fa fa-user"></i></button>

                               <div>
                               <a href="" onClick={this.zaboravljenaLozinka}>Zaboravili ste lozinku?</a>

                               </div>
                            </div>

                            </div>

                        </div>

                        </div>

                        <br />

                        <br />

                        <p className="text-center space-top">Nemate Nalog? Obratite nam se na mail <a href="mailto:office@furnix.rs">office@furnix.rs</a></p>

                        <br />

                        <br />

                    </div>


                    </div>

                </div>

                </div>

                </div>
            </div>
        );
    }

}

export default Login;