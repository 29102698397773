import React, { Component } from 'react';
import {CheckLogin} from '../GlobalneFje';
import ModalWindow from '../BigImg/ModalWindow';


class ProizvodKorpa extends Component {
    constructor(props) {
        super(props);
        this.state = { kol:this.props.kol, postoji:this.props.postoji,
            modalContent:null, modalCloseFunction:'modalClose', modalBtns:'', modalTitle:null };
        this.modalClose = this.modalClose.bind(this);
        this.modalCloseMustLogin = this.modalCloseMustLogin.bind(this);

        this.dodajProizvod = this.dodajProizvod.bind(this);
        this.changeKol = this.changeKol.bind(this);
    }
    
    modalClose(){
        this.setState({ modalContent:null,    modalCloseFunction:'modalClose',  modalBtns:'', modalTitle:null });
    }
    changeKol(e){
        let kol = e.target.value;
        this.setState({ kol:kol });
        this.props.setDisabled(false);
    }
    dodajProizvod(){
        let provera = CheckLogin();
        if (provera == 0) {
            this.setState({modalContent:"Morate se logovati, da biste naručili proizvode", modalCloseFunction:'modalCloseMustLogin' });
        }else{
            this.props.dodajProizvod(this.props.proizvod, this.state.kol, this.props.dodaci);
            let poruka = (this.state.postoji)? 'Proizvod promenjen' : "Proizvod u korpi";
            this.setState({modalContent:poruka, postoji:true });
        }

    }
    modalCloseMustLogin(){
        this.modalClose();
        window.location.href = '/login/'+ this.props.proizvod.Id;
    }
    formatCena (n) {
        var moneyFormatter2 = new Intl.NumberFormat('sr-RS', {  minimumFractionDigits: 2 });
        if(parseFloat(n) > 0)
            return moneyFormatter2.format(parseFloat(n));
        else 
            return '*na upit';
    }

    render() {
        let delovi = [];
        let dodaci = this.props.dodaci;
        let ukupno = (this.props.proizvod.Cena)? parseFloat(this.props.proizvod.Cena) : 0;
        let star = false;
        if(this.props.dodaci !== '')
            for(let deo in dodaci){
                if(dodaci[deo].naziv != "0"){
                    ukupno += parseFloat(dodaci[deo].cena);
                    delovi.push(
                        <tr key={deo}>
                            <td>{deo}</td>
                            <td>{dodaci[deo].naziv}</td>
                            <td><span className="cena">{this.formatCena (dodaci[deo].cena)}</span></td>
                        </tr>
                    );
                    if(parseFloat(dodaci[deo].cena) == 0) star = true;
                }
            }
        
        
        return (
                <div className="row col-12">
                    <ModalWindow onClose={this[this.state.modalCloseFunction]} btns={this.state.modalBtns} title={this.state.modalTitle}>
                        {this.state.modalContent} 
                    </ModalWindow>        
                    <table className="col-12 mx-auto table">
                        <tbody>
                        <tr><td colSpan="3" style={{"fontSize":'80%'}}>*cene oznacene * mogu se dobiti samo na upit</td></tr>
                        <tr className="thead-dark">
                            <th scope="col" className="thead-dark">Tip</th>
                            <th scope="col" className="thead-dark">Naziv</th>
                            <th scope="col" className="thead-dark"><span className="price">Cena</span></th>
                        </tr>

                        <tr>
                            <th scope="col">Osnovni Model</th>
                            <th></th>
                            <td><span className="cena">{this.formatCena(this.props.proizvod.Cena)}</span></td>
                        </tr>

                        {delovi}
                        <tr>
                            <th scope="col"><span className="product mr-3">Ukupno</span></th>
                            <td></td>
                            <td><span className="product price mr-3"> {(star)? '*' : ''}{this.formatCena(ukupno)}</span></td>
                        </tr>



                        <tr>
                            <th scope="col">Kolicina</th>
                            <td></td>
                            <td><span className="price"><input className="kolicina" min="1" value={this.state.kol} onChange={this.changeKol} type="number" /></span></td>
                        </tr>


                        </tbody>
                    </table>




                    <div className="col-12 d-flex align-items-end flex-column bd-highlight">
            <span className="product product-price mb-3"><i className="fa fa-tag pr-10"></i>Ukupna cena: {this.formatCena(this.state.kol * ukupno)}</span>

                        {/* <input type="submit" value={(this.state.postoji)? "POTVRDI" : "DODAJ"} onClick={this.dodajProizvod} className={"margin-clear btn btn-outline-dark float-right mt-5 "+this.props.blinkBtn} 
                        disabled={this.props.disabled}
                        /> */}
                    </div>
                </div>
            );
    }

}

export default ProizvodKorpa;