import React, { Component } from 'react';
import { ajaxCall } from './ajaxCall.js';


class Pretraga extends Component {
    constructor(props) {
        super(props);
        this.state = { proizvodi: [], greska: '' };
        this.ucitaniProizvodi = this.ucitaniProizvodi.bind(this);
    }
    ucitaniProizvodi(resp) {

        if (resp)
            if (resp.OKERR)
                this.setState({ proizvodi: resp.lines, greska: '' });
            else
                this.setState({ greska: [resp.msg] })
        else {
            this.setState({ greska: ['resp ne postoji'] });

        }
    }

    componentDidMount() {

        let rec = this.props.match.params.rec;

       ;//console.log(rec);

        var data = { sqlQuery: 'pr_slika2', whereCols: ['%'+rec+'%'] };
        ajaxCall('jinxapi/sqlParamQuery', 'GET', data, this.ucitaniProizvodi);
    }


    render() {

        let proizvodi = [];

        if (this.state.greska !== '')
            proizvodi = this.state.greska;
        else
            if (this.state.proizvodi.length > 0) {
               ;//console.log(this.state);
                proizvodi = this.state.proizvodi.map(pr => {
                    let a = "img/stolice/" + pr.slika;
                    let b = pr.naziv;
                    let c = pr.id;
                    let d = "proizvod/" + pr.id;
                    return (

                        <div key={c} className=" col-sm-6 col-md-4 col-lg-3 mx-5 mx-sm-2 d-flex justify-content-center news-card">
                            <a href={d} className="news-card__card-link"></a>
                            <img src={a} alt="" className="news-card__image" /> 
                            <div className="news-card__text-wrapper ">
                                <h2 className="news-card__title">{b}</h2>
                                <div className="news-card__post-date"></div>
                                <div className="news-card__details-wrapper">
                                    <p className="news-card__excerpt"></p>
                                    <a href={d} className="news-card__read-more">Detaljnije <i className="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>
                        </div>
                    )
                });
            }
        return (
            <div className="container-fluid content-wrapper ">
                {proizvodi}
            </div>
        );
    }

}

export default Pretraga;