import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import {CheckLogin} from './GlobalneFje';
import parse from 'html-react-parser';

class Footer extends Component{
	constructor(){
		super();
		this.state = { footerPodaci: [], greska:'', logovanId:0   };
		this.ucitanFooter = this.ucitanFooter.bind(this);
        this.logOut = this.logOut.bind(this);
	}
    logOut(){
        sessionStorage.setItem('logovan-id', 0);
        localStorage.setItem('logovan-id', 0);
        this.setState({ logovanId:0});
    }
	componentDidMount(){
        let id = CheckLogin();
        this.setState({ logovanId:id});
		ajaxCall('jinxapi/model/site/table', 'GET', {selCols:[
            "site_field_id",
            "site_field_adresa",
            "site_field_grad",
            "site_field_email",
            "site_field_telefon1",
            "site_field_telefon2",
            "site_field_telefon3",
            "site_field_brendovi_main_tekst",
            "site_field_onama_podnaslov",
            "site_field_blog_main_text"
        ]}, this.ucitanFooter);
	}
	ucitanFooter(resp){
		if(resp)
            if(resp.OKERR)
                this.setState({ footerPodaci: resp.lines, greska:'' });
            else 
                this.setState({ greska:[resp.msg]})
        else
            this.setState({ greska:['resp is undefined']});
	}
	render(){
        let footerPodaci = (this.state.footerPodaci.length > 0)? this.state.footerPodaci[0] : {onama_podnaslov: 'jok'};
        let a = (this.state.logovanId == 0)? 
            (<a className="text-light" href="/login">Login</a>) : 
            (<a className="text-light" href="#" onClick={this.logOut}>Log Out</a>);
        
        
        return (
        <>
            <footer className="page-footer font-small mdb-color pt-4 bg-black text-light">
                <div className="container text-center text-md-left">
                    <div className="row text-center text-md-left mt-3 pb-3">
                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold text-light">Furnix doo</h6>
                            <p>{parse(footerPodaci.onama_podnaslov)}</p>
                        </div>
                        <hr className="w-100 clearfix d-md-none"/>
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold text-light">Meni</h6>
                            {/* <p>
                                <a className="text-light" href="/proizvodi">Proizvodi</a>
                            </p> */}
                            <p>
                                <a className="text-light" href="/katalozi">Katalozi</a>
                            </p>
                            <p>
                                <a className="text-light" href="/brendovi">Brendovi</a>
                            </p>
                            <p>
                                <a className="text-light" href="/blog">Blog</a>
                            </p>
                        </div>
                        <hr className="w-100 clearfix d-md-none"/>
                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold text-light">Vaše strane</h6>
                            <p>
                                <a className="text-light" href="/nalog">Moj nalog</a>
                            </p>
                            <p>
                                <a className="text-light" href="/korpa">Korpa</a>
                            </p>
                            <p>
                                {a}
                            </p>
                            {/* <p>
                                <a className="text-light" href=""></a>
                            </p> */}
                        </div>
                        <hr className="w-100 clearfix d-md-none"/>
                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold text-light">Kontakt</h6>
                            <p>
                                <i className="fa fa-home mr-3"></i> {footerPodaci.adresa}, {footerPodaci.grad}</p>
                            <p>
                                <i className="fa fa-envelope mr-3"></i> e-mail: {footerPodaci.email}</p>
                            <p>
                                <i className="fa fa-phone mr-3"></i>{footerPodaci.telefon1}</p>
                            <p>
                                <i className="fa fa-print mr-3"></i>{footerPodaci.telefon2}</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row d-flex align-items-center">
                        <div className="col-md-7 col-lg-8">
                            <p></p>
                        </div>
                        <div className="col-md-5 col-lg-4 ml-lg-0 mb-5">
                            <ul className="social-links circle margin-clear animated-effect-1">
                                <li className="facebook"><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li className="twitter"><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li className="googleplus"><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                <li className="linkedin"><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                <li className="xing"><a href="#"><i className="fa fa-xing"></i></a></li>
                                <li className="skype"><a href="#"><i className="fa fa-skype"></i></a></li>
                                <li className="pinterest"><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                <li className="instagram"><a href="#"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <footer id="footer" className="clearfix ">
                <div className="subfooter">
                    <div className="container">
                        <div className="subfooter-inner">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="text-center">Copyright © 2020. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

		);
	}
}

export default Footer;
