import React, { useState, useEffect } from 'react';
import { promiseAjaxCall } from '../ajaxCall.js';
import BigImgDiv from "../BigImg/BigImgDiv";
import SliderJx from '../Slider/SliderJx';


//Primer fje sa ASYNC AWAIT

function ProizvodTabs2(props) {


    const [lines, setLines] = useState([]);
    const [htmlSlike, setHtmlSlike] = useState([]);


    useEffect(() => {
        let id = props.proizvodId;
        let ucitajLines = async () => {
            let data = {
                join:['materijali'],
                selCols:['materijali_field_naziv', 'materijali_field_slika_m', 'materijali_field_slika_v', 'proizvodi_materijali_field_redosled'],
                whereCols:[
                    {colName:'proizvodi_materijali_field_proizvod_id', colVal:id, oper:'=', logicOper:'AND'}
                ],
                orderBy:{'proizvodi_materijali_field_redosled': 'ASC'}
            }
            
            let resp = await promiseAjaxCall('jinxapi/model/proizvodi_materijali/table', 'GET', data);
            if (resp.OKERR){
                setLines(resp.lines); //umesto setState 
            }else{
               ;//console.log(resp.msg);  //greska je u pitanju
            }
        };
        ucitajLines();

    }, [props.proizvodId]);  //izvrsi ovaj useEffect samo kad proizvodId promeni


    useEffect(() => {
        let slike = [];
        for(let i=0;i<lines.length;i++)
            if(lines[i].materijali_field_slika_m !== null && lines[i].materijali_field_slika_m !=='')
                slike.push(<BigImgDiv bckgImg={"/img/materijali/"+lines[i].materijali_field_slika_v}>
                        <img src={"img/materijali/"+lines[i].materijali_field_slika_m} alt={lines[i].materijali_field_naziv} className="slika_materijal" />
                    </BigImgDiv>);
        setHtmlSlike(slike);
    }, [lines]);  //ovo pozivaj samo kad se lines promeni - kad se ucita ajaxCall


    return (
        <div className="tab-pane" id="h2tab2">

        <h4 className="space-top">Materijali</h4>

            {(htmlSlike.length>0)? 
            <>
            <hr />
            <SliderJx sliderChange="ltr" >
                {htmlSlike}
            </SliderJx>
            <hr />
            </>
            : <>Nema dostupnih slika<hr /></> }
        </div>
    );
}


/*

RAZLIKE:
 - nema setState - vec funkcija setPromenljiva za svaku promenljivu iz state
 - i sve ono sto smo izracunavali u render() sad moramo odmah kod pozivanje fje setPromenljiva 
 - ono sto je DidMount - to je useEffect
 
Primer sa inputom i/ili selectom koji menja state
 - const inputEl = useRef(null); //umesto createRef
    <input ref={inputEl} value={inVr} onChange={(e)=>setInVr(e.target.value)} />
    
 - useEffect moze nekoliko puta
 - poslednji argument u useEffect je [promenljiva], na ciju promenu zelimo da se poziva taj useEffect
 - ako kao poslednji arg stavim [] prazan niz, on se izvrsava samo jednom  (kao DidMount)
 - ono sto je Unmount - to je return u useEffect


 */
export default ProizvodTabs2;