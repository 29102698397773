import React, {Component} from 'react';
import {ajaxCall} from '../ajaxCall.js';

class MainBlog extends Component{
	constructor(){
		super();
		this.state = { blogs: [], greska:''  };
		this.ucitanBlog = this.ucitanBlog.bind(this);
	}

	componentDidMount(){
		ajaxCall('jinxapi/model/blog/table', 'GET', {
			orderBy: {blog_field_redosled: 'ASC'}
			// ,
			// linesPerRequest: 3,
			// pageNumber: 1

		}, this.ucitanBlog);
	}
	ucitanBlog(resp){
		if(resp)
            if(resp.OKERR)
    			this.setState({ blogs: resp.lines, greska:'' });
    		else 
    			this.setState({ greska:[resp.msg]})
        else
            this.setState({ greska:['resp is undefined']});
        
	}
	render(){
		let blogovi = [];
		if(this.state.greska !== '')
			blogovi = this.state.greska;
		else
			if(this.state.blogs.length > 0)
				for(let i=0;i<=2; i++){
					//if(this.state.blogs[i]){
					let blog = this.state.blogs[i];



				//blogovi = this.state.blogs.map(blog => {
				
					 let sl = "img/blog/"+blog.slika;
					// return (
					blogovi.push(
						<div className="col-md-3" key={blog.id}>

				        <div className="image-box style-2 mb-20 border">

				          <div className="overlay-container overlay-visible">

				          <img src={sl} alt="" />

				            <a href={"/blog/"+blog.id} className="overlay-link popup-img bg-black-trans "><i className="fa fa-plus"></i></a>

				            <div className="overlay-bottom bg-black hidden-xs m-0 p-1">

				              <div className="">

				                <p className="lead margin-clear  manjiFont text-left">{blog.naslov}</p>

				              </div>

				            </div>

				          </div>

				          <div className="body padding-horizontal-clear">

				            <p>{blog.kraci_text.substr(0,blog.kraci_text.indexOf(" ", 100))} ...<a href={"/blog/"+blog.id}> Više</a></p>

				          </div>

				        </div>

				      </div>
					);
				//});
				}
		

		return (
			<section id="services" className="light-gray-bg clearfix">

				  <div className="jumbotron pb-0 mb-0">

				    <div className="row justify-content-lg-center">

				      <div className="col-lg-8">

				        <h2 className="text-center mt-4"><strong>BLOG</strong> STRANA</h2>

				        <div className="separator"></div>

				      </div>

				    </div>

				    <div className="row">

				      

				      	{blogovi}

				      	<a href="/blog" className="col-md-3 bg-black main-blog">
							<p>Pratite novosti</p>				    
							<p>Čitajte <br className="d-none d-md-block" />naš blog</p>				    
					
						</a>

				    </div>

				  </div>

				</section>
		);
	}
}

export default MainBlog;
