import React, {Component} from 'react';
import {ajaxCall} from '../ajaxCall.js';

class MainBrendovi extends Component{
	constructor(){
		super();
		this.state = { brendovi: [], greska:''  };
		this.ucitaniBrendovi = this.ucitaniBrendovi.bind(this);
	}

	componentDidMount(){
		ajaxCall('jinxapi/model/brendovi/table', 'GET', {
			orderBy: {brendovi_field_redosled: 'ASC'}
			// ,
			// linesPerRequest: 3,
			// pageNumber: 1

		}, this.ucitaniBrendovi);
	}
	ucitaniBrendovi(resp){
		if(resp)
            if(resp.OKERR)
    			this.setState({ brendovi: resp.lines, greska:'' });
    		else 
    			this.setState({ greska:[resp.msg]})
        else
            this.setState({ greska:['resp is undefined']});
        
	}
	render(){
		let brendovi = [];
		if(this.state.greska !== '')
			brendovi = this.state.greska;
		else
			if(this.state.brendovi.length > 0)
				brendovi = this.state.brendovi.map(brend => {
				return(
					   <div className="col-md-6 col-lg-4 isotope-item app-development" key={brend.id}>
					      <div className="image-box text-center">
					        <div className="overlay-container">
					          <div className="slika1"  style={{backgroundImage:"url('img/brendovi/"+brend.slika_bck+"')"}}  ></div>
					          <div className="overlay-top bg-black-trans">
					            <div className="text">
					              <h3><a href={brend.web_adresa} target="_blank" >{brend.naziv}</a></h3>
					              <img className="mx-auto d-none d-sm-block" src={"img/brendovi/"+brend.logo} style={{height:"70px"}} alt="logo" />
					            </div>
					          </div>
					          <div className="overlay-bottom bg-black-trans">
					            <div className="links">
					              <a href={brend.web_adresa} target="_blank"  className="btn btn-gray-transparent btn-animated btn-sm">Poseti Web Site <i className="pl-10 fa fa-arrow-right"></i></a>
					            </div>
					          </div>
					        </div>
					      </div>
    					</div>
				)
				});
				
		return (
			<section id="portfolio" className="section pv-30 light-gray-bg">
				<div className="container">
					<div className="row justify-content-lg-center">
					<div className="col-lg-8 text-center">
						<h2 className="title"><strong>BRENDOVI</strong> <span className="text-muted">U PONUDI</span> </h2>
						<div className="separator"></div>
		<p className="large text-center">{/** Ovde moze neki text iz baze */}</p>
					</div>
					</div>
				</div>
				<div className="isotope-container2 row grid-space-0 mb-20">
					{brendovi}
				</div>
			</section>
		);
	} 
}

export default MainBrendovi;
