import React, { Component } from 'react';
import {CheckLogin} from './GlobalneFje';
import { ajaxCall } from './ajaxCall';

class Header extends Component {

    constructor(props) {

        super(props);
        this.state = { inputValue:'', logovanId:0, logovanUser: '' };
        this.inputRef = React.createRef();
        this.logOut = this.logOut.bind(this);
        this.setIme = this.setIme.bind(this);
    }
    logOut(){
        sessionStorage.setItem('logovan-id', 0);
        localStorage.setItem('logovan-id', 0);
        this.setState({ logovanId:0});
    }
    handleOnKeyUp = (e) => {
        if (e.keyCode == 13) {
	        window.location = "/pretraga/"+this.inputRef.current.value;
        	// ILI
	        //window.location = "/pretraga/"+this.state.inputValue;
        }
        //else{
        // 	this.setState({
	       //   	inputValue: e.target.value
	       //  });
        // }

    }
    componentDidMount(){
        let id = CheckLogin();
        this.setState({ logovanId:id});
        if(id>0)
        ajaxCall('jinxapi/model/users/:'+id, 'GET', {selCols:['users_field_naziv']}, this.setIme);
    }
    setIme(resp){
        if(resp && resp.OKERR){
            let l = resp.lines[0];
            this.setState({'logovanUser':l.naziv});
        }
    }
    render() {
        // <a href="/registracija" className="text-dark">REGISTRACIJA</a> / 
        let a = (this.state.logovanId == 0)? 
            (<><a href="/registracija" className="text-dark">REGISTRACIJA</a> / <a href="/login" className="text-dark">LOGIN</a></>) : 
            (<><span>Dobro došli {this.state.logovanUser }</span> <a href="#" onClick={this.logOut}>Log Out</a></>);
        return (
            <section className="container-fluid">
                <div className="row justify-content-around m-3">
                    <div>
                        {/* <a href="/korpa">
                            <button className="btn btn-outline-light ">
                                <img src="img/cart.png" alt="nita" /></button>
                        </a> */}
                    </div>
                    <button className="btn btn-outline-light text-dark">
                        {a}
                        
                    </button>
                     <input type="text" 

                    ref={this.inputRef}
                    onKeyUp={this.handleOnKeyUp} 
                    
                    className="h-input col-2 mt-3 form-control" placeholder="Traži" />
                    

                </div>
            </section>
        );
    }

}

export default Header;