import React, { Component } from 'react';
import { ajaxCall } from '../ajaxCall.js';
import SliderJx from '../Slider/SliderJx';

class MainSlider extends Component {
    constructor() {
        super();
        this.state = { slides: [], greska: '' };
        this.ucitanSlider = this.ucitanSlider.bind(this);
    }

    componentDidMount() {
        ajaxCall('jinxapi/model/slider/table', 'GET', {}, this.ucitanSlider);
    }
    ucitanSlider(resp) {
        if (resp)
            if (resp.OKERR)
                this.setState({ slides: resp.lines, greska: '' });
            else
                this.setState({ greska: [resp.msg] })
        else {
            this.setState({ greska: ['resp ne postoji'] });
        }

    }
    render() {
        let slike;
        if (this.state.greska !== '')
            slike = this.state.greska;
        else
            if (this.state.slides)
                slike = this.state.slides.map(slide => {
                    let a = (slide.redosled == 1) ? 'carousel-item active' : 'carousel-item';
                    let b = "img/slider/" + slide.slika;
                    return (
                       <div key={slide.redosled} className="main-slider-slika" style={{backgroundImage:'url("'+b+'")'}}></div>
                    )
                     /*<div key={slide.redosled} className={a}>
                            <img className="d-block w-100" src={b} alt="Second slide" />
                            </div>*/
                        
                });
            else
                slike = [];


        return (
            <section className="container-fluid" style={{ marginTop:"1em" }}>
                <div className="row no-gutters text-center">
                    <div className="bg-light col-3" >
                        <div id="logo" className="logo opacity2  d-none d-lg-block mt-5">
                            {/* <a href="/"><img id="logo_img_s" src="img/logo3.jpg"  alt="The Project" /></a> */}

                            <h4 className="text-center m-2 "><strong>KOMPLETNO </strong>OPREMANJE KANCELARIJA, KONFERENCIJSKIH SALA ARHIVNIH
							PROSTORA KAO I PROSTORA ZA ODMOR I RELAKSACIJU</h4>
                            <h4 className="text-center m-2 ">USLUGE <strong>DIZAJNA </strong>ENTERIJERA</h4>

                            <h4 className="text-center m-2 "><strong>PRAKTIČNA </strong>I PRILAGODLJIVA
							REŠENJA ZA SVE TIPOVE OBJEKATA I PROSTORIJA</h4>
                        </div>
                    </div>
                    
                    <div className="col-lg-9 mt-2 mt-lg-0 main-slider" >
                    <SliderJx sliderChange="ltr" >
                        {slike}
                    </SliderJx>
                </div>

                    <div id="logo" className="logo opacity2  d-lg-none mt-5">
                        {/* <a href="/"><img id="logo_img_s" src="img/logo3.jpg"  alt="The Project" /></a> */}

                        <h4 className="text-center m-2 "><strong>KOMPLETNO </strong>OPREMANJE KANCELARIJA, KONFERENCIJSKIH SALA ARHIVNIH
							PROSTORA KAO I PROSTORA ZA ODMOR I RELAKSACIJU</h4>
                            <h4 className="text-center m-2 ">USLUGE <strong>DIZAJNA </strong>ENTERIJERA</h4>

                        <h4 className="text-center m-2 "><strong>PRAKTIČNA </strong>I PRILAGODLJIVA
							REŠENJA ZA SVE TIPOVE OBJEKATA I PROSTORIJA</h4>
                    </div>


                </div>
            </section>
        );
    }
}

export default MainSlider;
