import React, { Component } from 'react';
import { ajaxCall } from './ajaxCall.js';

import parse from 'html-react-parser';

class BlogSingle extends Component {
    constructor(props) {
        super(props);
        this.state = { podaci: [], greska: '' };
        this.ucitaniPodaci = this.ucitaniPodaci.bind(this);
    }
    ucitaniPodaci(resp) {

        if (resp)
            if (resp.OKERR)
                this.setState({ podaci: resp.lines, greska: '' });
            else
                this.setState({ greska: [resp.msg] })
        else {
            this.setState({ greska: ['resp ne postoji'] });

        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        ajaxCall('jinxapi/model/blog/:'+id, 'GET', {}, this.ucitaniPodaci);
    }


    render() {

        let podaci;
        
        if (this.state.greska !== '')
            podaci = this.state.greska;
        else
            if (this.state.podaci.length > 0) {
                //;//console.log(this.state);
                let blog1 = this.state.podaci[0];

                const mystyle = {
                    background: "linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1) ), url('img/blog/" + blog1.slika + "') no-repeat center center fixed",
                    backgroundSize:"cover"
                };

                podaci = <div>
                            <div className="container-fluid mb-lg-3">
                                <div className="slikaBlog" style={ mystyle } >
                                    <div className="slikaBlog_in">
                                        <h1 className="font-weight-bolder">{blog1.naslov}</h1>
                                    </div>
                                </div>
                                <p className="container border p-md-5 px-2 py-5 bg-light blogtext text1">{parse(blog1.kraci_text)}</p>
                                <p className="container border p-md-5 px-2 py-5 bg-light blogtext text2">{parse(blog1.tekst)}</p>
                                {/* <p className="font-weight-bold">SHARE THIS ARTICLE</p>
                                <ul className="social-links circle margin-clear animated-effect-1 pb-3">
                                    <li className="facebook"><a href="#"><i className="fa fa-facebook"></i></a></li>
                                    <li className="twitter"><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li className="linkedin"><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    <li className="instagram"><a href="#"><i className="fa fa-instagram"></i></a></li>
                                </ul> */}
                            </div>
                        </div>
            }
        return (
            <div id="blog">
                {podaci}
            </div>
        );
    }

}

export default BlogSingle;

// import React, { Component } from 'react';
// import { ajaxCall } from './ajaxCall.js';


// class BlogSingle extends Component {
//     constructor(props) {
//         super(props);
//         this.state = { podaci: [], greska: '' };
//         this.ucitaniPodaci = this.ucitaniPodaci.bind(this);
//     }
//     ucitaniPodaci(resp) {

//         if (resp)
//             if (resp.OKERR)
//                 this.setState({ podaci: resp.lines, greska: '' });
//             else
//                 this.setState({ greska: [resp.msg] })
//         else {
//             this.setState({ greska: ['resp ne postoji'] });

//         }
//     }

//     componentDidMount() {
//         let id = this.props.match.params.id;
//         ajaxCall('jinxapi/model/blog/:'+id, 'GET', {}, this.ucitaniPodaci);
//     }


//     render() {

//         let podaci = [];

//         if (this.state.greska !== '')
//             podaci = this.state.greska;
//         else
//             if (this.state.podaci.length > 0) {
//                ;//console.log(this.state);
//                 podaci = this.state.podaci.map(pod => {
//                     return (
//                         <div>{JSON.stringify(pod)}</div>
//                     )
//                 })
//             }
//         return (
//             <div>
//                 BlogSingle Strana
//                 {podaci}
//             </div>
//         );
//     }

// }

// export default BlogSingle;

