import React, { Component } from 'react';
import { ajaxCall } from '../ajaxCall.js';
import ProizvodTabs1 from './ProizvodTabs1';
import ProizvodTabs2 from './ProizvodTabs2FAA';
import ProizvodTabs3 from './ProizvodTabs3F';

class ProizvodTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {proizvod:{} };
        this.ucitanProizvod = this.ucitanProizvod.bind(this);
    }

    componentDidMount() {
        let id = this.props.proizvodId;
        ajaxCall('jinxapi/model/proizvodi/:' + [id], 'GET', {}, this.ucitanProizvod);

    }
    ucitanProizvod(resp) {
       ;//console.log(resp);
        if (resp)
            if (resp.OKERR)
                this.setState({ proizvod: resp.lines[0], greska: '' });
            else
                this.setState({ greska: resp.msg })
        else
            this.setState({ greska: 'greska. resp from server: ' + JSON.stringify(resp) });
    }


    render() {
            return (
                <>

                    <div className="col-lg-8">
                    <ul className="nav nav-tabs style-4" role="tablist">

                        <li className="nav-item"><a className="nav-link active" href="#h2tab1" role="tab" data-toggle="tab"><i className="fa fa-files-o pr-1"></i>Dodatne Opcije</a></li>
                        
                        <li className="nav-item"><a className="nav-link" href="#h2tab2" role="tab" data-toggle="tab"  onClick={()=>this.props.promenjenaSlika('')}><i className="fa fa-star pr-1"></i>Materijali</a></li>

                        <li className="nav-item"><a className="nav-link" href="#h2tab3" role="tab" data-toggle="tab" onClick={()=>this.props.promenjenaSlika('')}><i className="fa fa-star pr-1"></i>Dimenzije</a></li>

                       
                        {/* <li className="nav-item"><a className="nav-link" href="#h2tab5" role="tab" data-toggle="tab"><i className="fa fa-star pr-1"></i>Lager</a></li>

                        <li className="nav-item"><a className="nav-link" href="#h2tab6" role="tab" data-toggle="tab"><i className="fa fa-star pr-1"></i>Peta Kolona</a></li> */}

                    </ul>
                    <div className="tab-content padding-top-clear padding-bottom-clear">

                    <ProizvodTabs1 proizvodId={this.props.proizvodId} potvrdiDodatke={this.props.potvrdiDodatke}  dodaci={this.props.dodaci} promenjenaSlika={this.props.promenjenaSlika} />
                    <ProizvodTabs2 proizvodId={this.props.proizvodId} />
                    <ProizvodTabs3 proizvodId={this.props.proizvodId}  />


                    </div>

                    </div>

                </>
            );
    }

}

export default ProizvodTabs;