import React, {Component} from 'react';
import {promiseAjaxCall, ajaxCall} from './ajaxCall.js';
import ModalWindow from './BigImg/ModalWindow';
import CaptchaJx from './Captcha/CaptchaJx.js';


class Kontakt extends Component {
	constructor(){
		super();
        this.state = { kontaktPodaci: [], greska:'', ime:'', email:'', tema:'', poruka:'',
            modalContent:null, modalCloseFunction:'modalClose', modalBtns:'', modalTitle:null,
            captcha_vr:false, captcha_vr_blink: false
        };
		this.ucitaniKontaktPodaci = this.ucitaniKontaktPodaci.bind(this);
		this.posaljiPoruku = this.posaljiPoruku.bind(this);
		this.modalClose = this.modalClose.bind(this);
		this.modalCloseRedirect = this.modalCloseRedirect.bind(this);
        this.captchaSet = this.captchaSet.bind(this);
	}
	ucitaniKontaktPodaci(resp){
		if(resp)
            if(resp.OKERR)
                this.setState({ kontaktPodaci: resp.lines, greska:'' });
            else 
                this.setState({ greska:[resp.msg]})
        else{
            this.setState({ greska:['resp ne postoji']})
        }
    }
	componentDidMount(){
        ajaxCall('jinxapi/model/site/table', 'GET', {}, this.ucitaniKontaktPodaci);;
    }
    posaljiPoruku(){
        if(this.state.captcha_vr === false){
            this.setState({ captcha_vr_blink: true })
            return;
        }else{
            this.setState({ captcha_vr_blink: false })
        }
        ( async()=>{
            
            let resp = await promiseAjaxCall('mail.php', 'POST', { 
                email:this.state.email, ime:this.state.ime,  tema:this.state.tema, msg: this.state.poruka, sta:"pitanje"
            });
           ;//console.log('resp', resp);
            if(resp.OKERR){
                this.setState({
                    modalContent:"Hvala na interesovanju! Odgovorićemo vam u najkraćem mogućem roku.", 
                    modalCloseFunction:'modalCloseRedirect'
                });
                
            }else{
                this.setState({ modalContent:(<p>{resp.msg}</p>), modalCloseFunction:'modalClose' });
            }
        })();
    }
    modalCloseRedirect(){
        window.location.href = "/";
    }
    modalClose(){
        this.setState({  modalContent:null, modalCloseFunction:'modalClose', btns:'', modalTitle:null });
    }
    captchaSet(vr){
        this.setState({captcha_vr:vr });
        if(this.state.captcha_vr_blink === true && vr === true)
            this.setState({captcha_vr_blink: false });
    }
	render() {
        let kontaktStyle = {
            backgroundImage:"url(img/bg2.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundSize: "cover"
        }
            let kontaktPodaci = (this.state.kontaktPodaci.length > 0)? this.state.kontaktPodaci[0] : {adresa: 'jok'};
	    return (
            <>
             <ModalWindow onClose={this[this.state.modalCloseFunction]} btns={this.state.modalBtns} title={this.state.modalTitle}>
                    {this.state.modalContent} 
                </ModalWindow>
            <div className="container-fluid" style={kontaktStyle}>
                <div className="row justify-content-lg-center">
                    <div className="col-lg-8 text-center pv-20">
                        <br/>
                        <br/>
                        <h1 className="page-title text-center display-4">Kontakt podaci</h1>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
            <section className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="main col-12 space-bottom">

                            <div className="row justify-content-center">

                                <div className="col-lg-6 mt-3">
                                    <h2 className="title text-center pt-5 mt-3 pb-3">PIŠITE NAM - POŠALJITE!</h2>
                                    <div className="contact-form">
                                        <div className="margin-clear">
                                            <div className="form-group has-feedback">
                                                <label htmlFor="name">Ime*</label>
                                                <input onChange={(e)=>this.setState({ime:e.target.value})} type="text" className="form-control" id="ime" required />
                                                <i className="fa fa-user form-control-feedback"></i>
                                            </div>
                                            <div className="form-group has-feedback">
                                                <label htmlFor="email">Email*</label>
                                                <input onChange={(e)=>this.setState({email:e.target.value})} type="email" className="form-control" id="email" aria-describedby="emailHelperText" required />
                                                <i className="fa fa-envelope form-control-feedback"></i>
                                            </div>
                                            <div className="form-group has-feedback">
                                                <label htmlFor="subject">Tema*</label>
                                                <input onChange={(e)=>this.setState({tema:e.target.value})} type="text" className="form-control" id="subject" required />
                                                <i className="fa fa-navicon form-control-feedback"></i>
                                            </div>
                                            <div className="form-group has-feedback">
                                                <label htmlFor="message">Poruka*</label>
                                                <textarea  onChange={(e)=>this.setState({poruka:e.target.value})} className="form-control" rows="6" id="message" required></textarea>
                                                <i className="fa fa-pencil form-control-feedback"></i>
                                            </div>
                                            <CaptchaJx setCaptcha={this.captchaSet} timeout={2000} blinkCaptcha={this.state.captcha_vr_blink} />
                                            <button className="submit-button btn btn-lg btn-default" onClick={this.posaljiPoruku}>Pošalji</button>
                                        </div>
                                    </div>
                                </div>

                                <aside className="col-lg-4 ml-xl-auto">
                                    <div className="sidebar">
                                        <div className="side vertical-divider-left text-center">
                                            <br/>
                                            <div className="separator mt-20"></div>
                                            <br/>
                                            <ul className="list">
                                                <li><i className="fa fa-home pr-10"></i>{kontaktPodaci.adresa} <br/><span className="pl-20">{kontaktPodaci.grad}</span></li>
                                                <li><i className="fa fa-phone pr-10"></i><abbr title="Phone">P:</abbr> {kontaktPodaci.telefon1}</li>
                                                {/* <li><i className="fa fa-mobile pr-10 pl-1"></i><abbr title="Phone">M:</abbr>{kontaktPodaci.telefon2}</li> */}
                                                <li><i className="fa fa-envelope pr-10"></i><a href="#">{kontaktPodaci.email}</a></li>
                                            </ul>
                                            <ul className="social-links colored">
                                                <li className="facebook"><a href="/"><i className="fa fa-facebook"></i></a></li>
                                                <li className="twitter"><a href="/"><i className="fa fa-twitter"></i></a></li>
                                                <li className="instagram"><a href="/"><i className="fa fa-instagram"></i></a></li>
                                                <li className="youtube"><a href="/"><i className="fa fa-youtube"></i></a></li>
                                            </ul>
                                            <br/>
                                            <div className="separator mt-20 "></div>
                                            <br/>
                                            <div className="image-box team-member mb-20">
                                                <div className="overlay-container overlay-visible">
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1415.426554221645!2d20.486692281136225!3d44.8041821591712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7a9c97bb0ed5%3A0x46508ab1bab21dc9!2z0JTQuNC80LjRgtGA0LjRmNCwINCi0YPRhtC-0LLQuNGb0LAgNTIsINCR0LXQvtCz0YDQsNC0!5e0!3m2!1ssr!2srs!4v1597425447548!5m2!1ssr!2srs" width="320" height="300" frameBorder="0" 
                                                style={{ border:0, "boxShadow":"3px 3px 6px #333" }} allowFullScreen="" aria-hidden="false" tabIndex="0" title="google map"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
	    );
	}
}

export default Kontakt;

