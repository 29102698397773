import React, { Component } from 'react';
import { ajaxCall } from './ajaxCall.js';
import Slider from "react-slick";
import ProizvodSlicni from "./Proizvod/ProizvodSlicni";
import ProizvodTabs from "./Proizvod/ProizvodTabs";
import ProizvodKorpa from "./Proizvod/ProizvodKorpa";
import ProizvodDeoSlika from "./Proizvod/ProizvodDeoSlika";
import BigImg from "./BigImg/BigImg";
import ProizvodOpis from "./Proizvod/ProizvodOpis";
import {Korpa} from './GlobalneFje';
import BigImgDivJx from './BigImg/BigImgDivJx.js';

class Proizvod extends Component {
    constructor(props) {
        super(props);
        this.ucitanProizvod = this.ucitanProizvod.bind(this);
        this.ucitaneSlike = this.ucitaneSlike.bind(this);
        this.potvrdiDodatke = this.potvrdiDodatke.bind(this);
        this.dodajProizvod = this.dodajProizvod.bind(this);
        this.promenjenaSlika = this.promenjenaSlika.bind(this);
        this.setDisabledBtnDodaj = this.setDisabledBtnDodaj.bind(this);
        this.showBigImg = this.showBigImg.bind(this);
        this.showBigImgFromDiv = this.showBigImgFromDiv.bind(this);
        this.closeBigImg = this.closeBigImg.bind(this);
        this.korpa = new Korpa();
        let vr = this.ucitajDodatkeIzKorpe();
        this.state = { proizvod1: {}, greska: '', slike: [],  nav1: null, nav2: null, dodaci:vr.dodaci, kol:vr.kol, blinkBtn:'', disabledBtnDodaj:false, deoSlika:'', deoCena:'', bigImgClosed:true, bigImgSrc:'' };
        
    }


    componentDidMount() {
        let id = this.props.match.params.id;
        ajaxCall('jinxapi/model/proizvodi/:' + [id], 'GET', {}, this.ucitanProizvod);

        let data = {
            whereCols: [
                { colName: 'proizvodi_slike_field_proizvod_id', colVal: id, oper: '=', logicOper: 'AND' }
            ],
            orderBy:{ 'proizvodi_slike_field_redosled':'ASC'}
        };
        ajaxCall('jinxapi/model/proizvodi_slike/table', 'GET', data, this.ucitaneSlike);
        this.ucitajDodatkeIzKorpe();
    }
    ucitajDodatkeIzKorpe(){
        let pr = this.korpa.lista.find(pr => pr.id==this.props.match.params.id);
       ;//console.log('KORPA PR:', pr)
        if(pr){
            return ({ dodaci: pr.dodaci, kol: parseInt(pr.kol) });
        }else{
            return ({ dodaci: '', kol:1 });
        }
    }
    ucitanProizvod(resp) {
       ;//console.log(resp);
        if (resp)
            if (resp.OKERR)
                this.setState({ proizvod1: resp.lines[0], greska: '' });
            else
                this.setState({ greska: resp.msg })
        else
            this.setState({ greska: 'greska. resp from server: ' + JSON.stringify(resp) });
    }
    ucitaneSlike(resp) {
       ;//console.log(resp);
        if (resp)
            if (resp.OKERR)
                this.setState({ slike: resp.lines, greska: '',  nav1: this.slider1,
                nav2: this.slider2 });
            else
                this.setState({ greska: [resp.msg] })
        else {
            this.setState({ greska: ['resp ne postoji'] });
        }
    }

    potvrdiDodatke(selectVrednosti){
        this.setState({ dodaci:selectVrednosti, blinkBtn: 'blink-btn'});
        this.setState({ disabledBtnDodaj:false });
    }
    setDisabledBtnDodaj(t){
        this.setState({ disabledBtnDodaj:t });
    }
    dodajProizvod(p, kol, dodaci){
        this.korpa.dodajProizvod(p, kol, dodaci);
        this.setState({ blinkBtn: '', disabledBtnDodaj: true});
    }
    promenjenaSlika(img, cena){
        this.setState({ deoSlika: img, deoCena: cena });
    }


    showBigImg(e){
        this.setState({ bigImgClosed:false, bigImgSrc:e.target.src });
    }
    showBigImgFromDiv(e){
        this.setState({ bigImgClosed:false, bigImgSrc:e.target.getAttribute('data-src') });
    }
    closeBigImg(){
        this.setState({ bigImgClosed:true, bigImgSrc:'' });
    }
    
    render() {
        let slika, slike, max_slides;


        if (this.state.greska !== '') {
            slika = this.state.greska;
        }

        let stateSl = this.state.slike.slice(0);
        let s1 = stateSl.shift();
        stateSl.push(s1);
       ;//console.log("stateSl", stateSl)
        if (this.state.slike && this.state.slike.length > 0) {
           ;//console.log("len", stateSl.length,this.state.slike.length)
            slika = stateSl.map(sli => (
                <div className="overlay-container overlay-visible " key={sli.id}>
                    <div style={ {backgroundImage: "url('img/stolice/"+ sli.slika_v + "')", height:"50vh", width:"100%" }} className="slickVelika slika" onClick={this.showBigImgFromDiv} data-src={"img/stolice/"+ sli.slika_v} />
                </div>
            ));
            //
            if(stateSl.length>1){
                slike = stateSl.map(sli => (
                    <div className="slick-nav-thumb slickMala" key={sli.id}>
                        <div className="border mb-3 hc-shadow-2 slika" 
                            style={ {backgroundImage: "url('img/stolice/"+ sli.slika_m+"')", height:"20vh" } } ></div>
                    </div>
                ));
                //s1 = slike.shift();
                //slike.push(s1);
            }else
                slike = <></>;
            max_slides = (slike.length >= 3)? 3 : slike.length;
        } else {
            max_slides = 0;
            slika = [];
            slike = [];
        }
        let imeProizvoda = this.state.proizvod1.Naziv;


        return (
            <>
            <BigImg bckgImg={this.state.bigImgSrc} closed={this.state.bigImgClosed} onClose={this.closeBigImg} />
            <section className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="main col-12">
                            <h1 className="page-title">{imeProizvoda}</h1>
                            <div className="separator-2"></div>
                            <div className="row">
                                <div className="col-lg-5" >
                                    <Slider
                                        asNavFor={this.state.nav2}
                                        ref={slider => (this.slider1 = slider)}
                                        nextArrow ={<></>}
                                        prevArrow ={<></>}
                                        >
                                        {slika}
                                    </Slider>
                                    <Slider
                                        asNavFor={this.state.nav1}
                                        ref={slider => (this.slider2 = slider)}
                                        slidesToShow={max_slides}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        nextArrow ={<></>}
                                        prevArrow ={<></>}
                                    >
                                        {slike}
                                    </Slider>
                                </div>
                                <ProizvodOpis proizvod={this.state.proizvod1} />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="pv-30 light-gray-bg">
                {this.props.proizvodId}
                <div className="container">

                    <div className="row">
                        <ProizvodTabs proizvodId={this.props.match.params.id} potvrdiDodatke={this.potvrdiDodatke} dodaci={this.state.dodaci} promenjenaSlika={this.promenjenaSlika} />
                        {/* <div><img src="img/delovi/1.jpg" alt="nesto" /></div> */}
                        <ProizvodDeoSlika slika={this.state.deoSlika} cena={this.state.deoCena} />
                        {/* <ProizvodSlicni proizvodId={this.props.match.params.id} /> */}
                        <ProizvodKorpa proizvod={this.state.proizvod1} dodaci={this.state.dodaci} dodajProizvod={this.dodajProizvod} kol={this.state.kol} postoji={this.korpa.postojiProizvod(this.props.match.params.id) }
                        blinkBtn={this.state.blinkBtn} disabled={this.state.disabledBtnDodaj} setDisabled={this.setDisabledBtnDodaj}
                        />
                
                    </div>

                </div>

            </section>
            </>
        );
    }
}

export default Proizvod;