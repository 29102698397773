import React from 'react';


function ProizvodDeoSlika(props){
    function formatCena (n) {
        var moneyFormatter2 = new Intl.NumberFormat('sr-RS', {  minimumFractionDigits: 2 });
        return moneyFormatter2.format(parseFloat(n));
    }
    //console.log("PROPS: ", props);
    return (
        <div className={((props.slika !== "")? "proizvod-slika-deo" : "")+" col-lg-4 col-xl-3"}>

                    {/* <div className="sidebar">

                        <div className="block clearfix"> */}

                            <div style={{ 
                                backgroundImage: 'url("img/delovi/'+props.slika+'")', 
                             }}></div>
                    <p className="cena">{((props.cena)? "Cena: "+((props.cena>0 && props.cena !== '')? formatCena(props.cena)+" RSD" : '*na upit') : '')}</p>
                        {/* </div>
                    </div> */}
        </div>

    )
}
export default ProizvodDeoSlika;