import React, {Component} from 'react';
import { BrowserRouter, Route} from 'react-router-dom';
import './App.css';

import Header from './components/Header';
// import Menu from './components/MenuNavLink';
import Menu from './components/MenuBrowserRouter';
import Footer from './components/Footer';
import Main from './components/Main';
import Brand from './components/Brand';
import Katalozi from './components/Katalozi';
import Blog from './components/Blog';
import Onama from './components/Onama';
import Kontakt from './components/Kontakt';
import Grupa from './components/Grupa';
import Proizvod from './components/Proizvod';
import Pretraga from './components/Pretraga';
// import KorpaStranica from './components/KorpaStranica';
import Registracija from './components/Registracija';
import Login from './components/Login';
import BlogSingle from './components/BlogSingle';
import MojNalog from './components/MojNalog';
// import KorpaPregled from './components/KorpaPregled';

class App extends Component {

  render(){
    return (
      <div>
        <Header />
        <Menu />
        <BrowserRouter>
            <Route exact path="/" component={Main} />
            <Route path="/brendovi" component={Brand} />
            <Route path="/katalozi" component={Katalozi} />
            <Route exact path="/blog" component={Blog} />
            <Route path="/onama" component={Onama} />
            <Route path="/kontakt" component={Kontakt} />
            <Route path="/grupa/:id" component={Grupa} />
            <Route path="/proizvod/:id" component={Proizvod} />
            <Route path="/pretraga/:rec" component={Pretraga} /> 

            {/* <Route exact path="/korpa" component={KorpaStranica} />  */}
            {/* <Route path="/korpa/:id" component={KorpaPregled} />  */}
            <Route path="/nalog" component={MojNalog} /> 
            <Route path="/registracija" component={Registracija} /> 
            <Route exact path="/login" component={Login} /> 
            <Route path="/login/:proizvodid" component={Login} /> 
            <Route path="/blog/:id" component={BlogSingle} /> 
        </BrowserRouter>
        <Footer />
      </div>
    );
  }
}

export default App;
