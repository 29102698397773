import React, { useState, useEffect } from 'react';
import './BigImgDivJx.css'; 
import closeImg from './xred.png';

function BigImgDivJx(props){
    const [closed, setClosed] = useState(true);
    const [bckgImg, setBckgImg] = useState(props.bckgImg);

    const [children, setChildrenClick] = useState([]);

    useEffect(()=>{
        let newCh = React.cloneElement(props.children, { onClick: ()=>{setClosed(false)}  } );
        setChildrenClick(newCh);
        setBckgImg(props.bckgImg);
    }, [props.children, props.bckgImg]);

    function sadrzajOnClick(e){
        e.stopPropagation();
    }
    function closeOnClick(e){
        setClosed(true);
    }

    return (
        <>
            {children}
            <div className={"bidjx_wrapper"+((closed)? ' closed' : '')}  onClick={closeOnClick}>
                <div className="bidjx_close"><img src={closeImg} onClick={closeOnClick} alt="X" /></div>
                <div className="bidjx_sadrzaj" onClick={sadrzajOnClick}  style={{ backgroundImage: 'url("'+bckgImg+'")' }} ></div>
            </div>
        </>
    );
}

export default BigImgDivJx;