function json2url(data){
	return data2url(data, '').substr(1);
}
function data2url(data, parent){
	var str = '';
	for(var key in data){
		if(typeof data[key] === 'object'){
			var p = (parent =='')? key : parent+'['+key+']';
			str += data2url(data[key], p);
		}else{
			var k = encodeURIComponent(key);
			var val = encodeURIComponent(data[key]);
			str += (parent == '')? '&'+k+'='+val : '&'+parent+'['+k+']='+val;
		}
	}
	return str;
}

let brojacg = 0;

function transformRequest(url, method, data){
	brojacg++;
	//if(process.env.NODE_ENV == 'production')
		url = window.location.protocol + "//" + window.location.host + "/admin/" + url;
		//probaj i samo "/admin/" + url;
	//else
		//url = process.env.REACT_APP_folder + "/public/admin/" + url;

	//     
	//console.log('URL:', url);

	if(method !== 'GET' && method !== 'POST' && data.jinxMethod === undefined)
		data.jinxMethod = method;
	let nMethod = (method == 'GET')? method : 'POST'; 
	let bData = {method: nMethod};

	let sData = JSON.stringify(data);
	if(method == 'POST')
		bData = {
			method: nMethod,
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			//credentials: 'same-origin', // include, *same-origin, omit
			headers: {
			'Content-Type': 'application/json'
			// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: sData // body data type must match "Content-Type" header
		};
	if(method == 'GET')
		url = url + ((sData == '{}')? '' : '?'+json2url(data));
	return [url, bData];
}

export async function promiseAjaxCall(url, method, data){	
	let nUrl, bData;
	[nUrl, bData] = transformRequest(url, method, data);
	let brojac = brojacg;
	////console.log('request: '+url, brojac, bData);

	let response = await fetch(nUrl, bData);
	let tData = await response.text();

	var rData = 'Nema odgovora';
	if(tData && tData.trim() !== ''){
		try{
			rData = JSON.parse(tData);
			errorReport(rData, brojac, 'green');
		}catch(e){
			rData = 'Greska. Vidi log.';
			errorReport('Cant parse to JSON: '+tData,  brojac);
		}
	}
	return rData;
}


let ajaxList = [];

export function ajaxCall(url, method, data, callback){	
	//console.log(ajaxList.length);
	ajaxList.push({ url:url, method:method, data:data, callback:callback });
    if(ajaxList.length > 1)  return;
    ajaxCallNext(url, method, data, callback);
}


export function ajaxCallList(url, method, data, callback){
    ajaxList.push({ url:url, method:method, data:data, callback:callback });
    if(ajaxList.length > 1)  return;
    ajaxCallNext(url, method, data, callback);
}
function ajaxCallNextInList(){
	ajaxList.shift();
	if(ajaxList.length > 0)
		ajaxCallNext( ajaxList[0].url, ajaxList[0].method, ajaxList[0].data, ajaxList[0].callback);
}
function ajaxCallNext(url, method, data, callback){	
	let bData;
	[url, bData] = transformRequest(url, method, data);
	let brojac = brojacg;
	//console.log('request: '+url, brojac, bData);

	fetch(url, bData)
		.then(resp => resp.text())
		.then(tData => {
			var rData;
			try{
				rData = JSON.parse(tData);
				errorReport(rData,  brojac, 'green');
				if(callback && typeof callback == 'function') 
					callback(rData);  
			}catch(e){
				errorReport('Cant parse to JSON: '+tData,  brojac);
				if(callback && typeof callback == 'function') 
					callback({OKERR:false, data:tData });
			}finally{
				ajaxCallNextInList();
			}
		});
}



function errorReport(data, brojac, err){
	// if(err)
	// 	//console.log('ajaxCallReport: ',  brojac, data);
	// else
	// 	//console.log('ERROR:', data);
}