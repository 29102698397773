import React, { useState, useEffect } from 'react';
import {CheckLogin} from './GlobalneFje';
import { promiseAjaxCall } from './ajaxCall';
import TablePodaci1 from './MojNalog/TablePodaci1';
import TablePodaci2 from './MojNalog/TablePodaci2';
import SveKorpe from './MojNalog/SveKorpe';

function MojNalog(){

    
    const [user, setUser] = useState({});
    
    useEffect( ()=>{ 
        (async ()=>{
            let id = CheckLogin();
            if(id>0){
                let user = await promiseAjaxCall('jinxapi/model/users/:'+id, 'GET', {});
                setUser(user.lines[0]);
            }
        })();
    }, []);


    return (
        <div className="main-container">
            <div className="container" style={(user && user.id>0)? { display:'none'} : {}}>
                Morate se <a href="/login">LOGOVATI</a> da biste gledali ovu stranu.
            </div>
            <div className="container mx-auto" style={(!(user && user.id>0))? { display:'none'} : {}}>

                <h2 class="title text-center"><span class="text-muted">MOJ</span> <strong>NALOG</strong></h2>
                <div class="separator m-5"></div>
                <div class="row mb-4">
                    <TablePodaci1 user={user} />
                    <TablePodaci2 user={user} />
                    <div className="col-12 col-lg-4 ml-4" 
                        style={{background: 'url("/img/o_nama2.jpg") no-repeat center / cover', height:"16rem"}}></div>

                </div>

                <hr className="my-5" />

                <SveKorpe userId={user.id} />
            </div>

        </div>
    )
}
export default MojNalog;