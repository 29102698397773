import React, { useState, useEffect } from 'react';
import { promiseAjaxCall } from '../ajaxCall.js';
import parse from 'html-react-parser';

function ProizvodOpis(props){
    const osobineList = {
        'Konstrukcija':'KONSTRUKCIJA', 
        'Glavonaslon':'GLAVONASLON',
        'Naslon':'NASLON', 
        'LumbalnaPodrska':'LUMBALNA PODRŠKA',
        'Rukonasloni':'RUKONASLONI', 
        'Sediste':'SEDIŠTE', 
        'Baza':'BAZA', 
        'Tockici':'TOČKIĆI',
        'Stope':'STOPE', 
        'Slaganje':'SLAGANJE', 
        'Nizanje':'NIZANJE', 
        'Tapacir':'TAPACIR', 
        'Mehanizam':'MEHANIZAM',
        'Nosivost':'NOSIVOST',
        'TablaZaPisanje':'TABLA ZA PISANJE'
    }

    const [deklaracijaOpis, setDeklaracija] = useState('');
    const [specifikacija, setSpecifikacija] = useState([]);
    useEffect(() => {
        osobine();
        let id = (props.proizvod.deklaracija_id)?? 1;
        if(id)
            (async () => {
                let resp = await promiseAjaxCall('jinxapi/model/deklaracije/:'+id, 'GET', {});
                if (resp.OKERR){
                    setDeklaracija(resp.lines[0].text);
                }else{
                   ;//console.log(resp.msg);  //greska je u pitanju
                }
            })();
        else
           ;//console.log("NEMA DEKLARACIJE_ID");
    }, [props.proizvod]);  //izvrsi ovaj useEffect samo kad proizvodId promeni

    function osobine(){
        let pro = props.proizvod; 
        let niz = [];
        for (let keys in pro) {
            if (osobineList[keys] && pro[keys] !== '' && pro[keys] !== null)
                niz.push(
                    <div className="row" key={keys}>
                        <span className="col-5 col-xs-4 font-weight-bold">{osobineList[keys]}:</span>
                        <span className="col-6 ml-3">{pro[keys]}</span>
                    </div>)
        }
        setSpecifikacija(niz);
    }
    return (
        <div className="col-lg-7 pv-30">
            <div className="">
                <h2 className="mt-4">Opis Proizvoda</h2>
                <div className="text_opis m-2" >
                    <p>{props.proizvod.Opis}</p>
                    <p><b>Deklaracija:</b><br/>{parse(deklaracijaOpis)}</p>
                </div>
                <h4 className="space-top">Specifikacija</h4>
                <hr />
                <div className="container">
                    <dl className="">

                        {specifikacija}

                    </dl>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default ProizvodOpis;
