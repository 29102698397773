import React, {Component} from 'react';
import MainSlider from './Main/MainSlider';
import MainBlog from './Main/MainBlog';
import MainBrendovi from './Main/MainBrendovi';
import MainNajprodavaniji from './Main/MainNajprodavaniji';

class Main extends Component {
  render(){
    return (
      <div>
         <MainSlider />  
         <MainBlog />  
         <MainNajprodavaniji />  
         <MainBrendovi />  
      </div>

    );
  }
}

export default Main;
