import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';


class Katalozi extends Component{
	constructor(){
		super();
		this.state = { katalozi: [], greska:''  };
		this.ucitanKatalog = this.ucitanKatalog.bind(this);
	}
	componentDidMount(){
		ajaxCall('jinxapi/model/katalozi/table', 'GET', {
			orderBy: {katalozi_field_redosled: 'ASC'}
		}, this.ucitanKatalog);
	}
	ucitanKatalog(resp){
		if(resp)
            if(resp.OKERR)
                this.setState({ katalozi: resp.lines, greska:'' });
            else 
                this.setState({ greska:[resp.msg]})
        else{
            this.setState({ greska:['resp ne postoji']});
        }

	}
	render(){
		let katalozi = [];
		if(this.state.greska !== '')
			katalozi = this.state.greska;
		else
            if(this.state.katalozi.length > 0)
                katalozi = this.state.katalozi.map((katalog,i) => {

					let a = (i == 1)? 'image-box space-top style-4' : 'image-box style-4';
                    let b = (i % 2 == 0)? 'col-lg-6 order-lg-2' : 'col-lg-6';
                    let e = (i % 2 == 0)? 'col-lg-6 order-lg-1' : 'col-lg-6';
                    let f = (i % 2 == 0)? 'body text-right' : 'body';
                    let d = (i % 2 == 0)? 'separator-3' : 'separator-2';
                    let c = "/img/katalozi/" + katalog.slika;
                    // const slika = require(c);
                    return(
                        <div key={katalog.redosled} className={a}>
                            <div className="row grid-space-0">
                                <div className={b}>
                                    <div className="overlay-container">
                                        <img src={c} alt="xvxvxvx"/>
 
                                    </div>
                                </div>
                                <div className={e}>
                                    <div className={f}>
                                        <div className="pv-30 hidden-lg-down"></div>
                                        <h2>{katalog.naziv}</h2>
                                        <div className={d}></div>
                                        <br/>
                                        <a href={"img/katalozi/"+katalog.pdf} className="btn btn-lg btn-gray-transparent btn-sm btn-animated margin-clear" target="_blank">Otvori<i className="fa fa-arrow-right pl-10"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })

		return (
            <section className="main-container border-clear light-gray-bg padding-bottom-clear">
                <div className="container">
                    <div className="row">
                        <div className="main col-12">
                            <h1 className="page-title text-center display-4">Katalozi</h1>
                            {katalozi}
                        </div>
                    </div>
                </div>
            </section>

		);
	}
}

export default Katalozi;
