import React from 'react';

export default function TablePodaci1(props){
    return(
        <table className="table col-lg-7 d-none d-lg-block podaci_usera">
                        <thead className="thead-dark">
                            <tr>
                                <th colspan="4">VAŠI PODACI:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th  className="py-3" scope="row">PIB:</th>
                                <td  className="py-3">{props.user.pib}</td>
                                <th  className="py-3" scope="row">telefon:</th>
                                <td  className="py-3">{props.user.telefon}</td>
                            </tr>
                            <tr>
                                <th  className="py-3" scope="row">naziv:</th>
                                <td  className="py-3">{props.user.naziv}</td>
                                <th  className="py-3">kontakt:</th>
                                <td  className="py-3">{props.user.kontakt}</td>
                            </tr>
                            <tr>
                                <th  className="py-3" scope="row">adresa:</th>
                                <td  className="py-3">{props.user.adresa}</td>
                                <th  className="py-3" scope="row">email:</th>
                                <td  className="py-3">{props.user.email}</td>
                            </tr>
                            <tr>
                                <th  className="py-3" scope="row">maticni_broj:</th>
                                <td  className="py-3">{props.user.maticni_broj}</td>
                            </tr>
                        </tbody>
            </table>
        );
}

