import React, {Component} from 'react';
import loading from './loading.gif';
import check from './check.png';
import x from './xred.png';
import './Captcha.css';

class CaptchaJx extends Component {
    constructor(props){
        super(props);
        
        this.state = {  brojevi:[], s1:0, s2:0, znak:0, sel:-1, ikonica: "", timeout:(props.timeout)?? 2000   };

        this.proveri = this.proveri.bind(this);
        this.setRez = this.setRez.bind(this);
        this.start = this.start.bind(this);
    }

    componentDidMount(){
        this.start();
    }
    start(){
        let x = [];
        for(let i=2; i<=18; i++) x.push(i);
        x = this.shuffle(x);
        x = x.slice(0,6);
        let zn = 0; //Math.floor(Math.random()*2);
        let s1 = Math.floor(Math.random()*9+1);
        let s2 = Math.floor(Math.random()*9+1);
        if(x.indexOf(s1+s2) < 0)
            x.push(s1+s2);
        do{
            x = this.shuffle(x);
        }while(x.indexOf(s1+s2) < 2);
        this.setState({ brojevi:x, s1:s1, s2:s2, sel:-1, ikonica:"", znak:(zn===0)? '+' : '-' });
    }
    shuffle(array) {
        var tmp, current, top = array.length;
        if(top) while(--top) {
          current = Math.floor(Math.random() * (top + 1));
          tmp = array[current];
          array[current] = array[top];
          array[top] = tmp;
        }
        return array;
    }

    proveri(e){
        this.setState({  ikonica: loading })
        let vr = parseInt(e.target.value);
        let uslov = vr === this.state.s1+this.state.s2;
        setTimeout( ()=>this.setRez(uslov), this.state.timeout);
    }
    setRez(uslov){
        this.props.setCaptcha(uslov);
        this.setState({ ikonica: (uslov)? check : x});
        if(!uslov)
            setTimeout(this.start, 1000);
    }
    
    render() {
        let slovima = ["nula", 'jedan', 'dva', 'tri', 'četiri', 'pet', 'šest', 'sedam', 'osam', 'devet', 'deset', 'jedanaest', 'dvanaest', 'trinaest', 'četrnaest', 'petnaest', 'šesnaest', 'sedamnaest', 'osamnaest' ];
  
        return (
            <div className={"captchaJx "+((this.props.blinkCaptcha)? "captchaJxblink" : "")}>
                <div className="contentJx">
                    <span>
                        {slovima[this.state.s1]}
                        {this.state.znak}
                        {slovima[this.state.s2]}
                        =
                    </span>
                    <select onChange={this.proveri} defaultValue="-1" className="vanilaSelectJx">
                        <option value="-1" key={-1}> </option>
                        {this.state.brojevi.map(broj => <option value={broj} key={broj}>{slovima[broj]}</option>)}
                    </select>
                    <div className="checkingJx">
                        <img src={this.state.ikonica}  className="imgJx" alt="" />
                    </div>
                </div>
            </div>
        );
    
    }
}

export default CaptchaJx;
