import React, { useState, useEffect } from 'react';
import { promiseAjaxCall } from '../ajaxCall.js';
import parse from 'html-react-parser';
import BigImgDiv from "../BigImg/BigImgDiv";

function ProizvodTabs3(props){
    const [izr, setIzr] = useState('');
    
    useEffect(() => {
        async function ucitajPodatke(){
            let id = props.proizvodId;
            let data = {
                whereCols:[
                    {colName:'proizvodi_dimenzije_field_proizvod_id', colVal:id, oper:'=', logicOper:'AND'}
                ],
            }
            let resp = await promiseAjaxCall('jinxapi/model/proizvodi_dimenzije/table', 'GET', data);
            if (resp.OKERR){
                //napraviDiv(resp.lines[0]);
                setIzr(resp.lines[0]);
            }else{
                setIzr(resp.msg);
            }
           
        };
        ucitajPodatke();
    }, []);
    

    function napraviDivNinja(red){
        if(red.slika_m && red.slika_m !== ''){
            return (
                <BigImgDiv bckgImg={"img/dimenzije/"+red.slika_v}>
                    <img src={"img/dimenzije/"+red.slika_m} alt={red.text} className="slika_materijal" />
                </BigImgDiv>
            )
        }else{
            if(red.text && red.text !== '')
                return (<div>{parse(red.text)}</div>)
            else
                return (<div>Nema opisa</div>)
        }
    }
    return (
        <div className="tab-pane" id="h2tab3">

        <h4 className="space-top">Dimenzije</h4>
      
        {napraviDivNinja(izr)}
    
        </div>
    )
}

export default ProizvodTabs3;
