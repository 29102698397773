import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import SliderJx from './Slider/SliderJx';

class Blog extends Component{
    constructor(){
		  super();
		  this.state = { blogovi: [], greska:'' , slider:[] };
      this.ucitanBlog = this.ucitanBlog.bind(this);
	  }
	  componentDidMount(){
		    ajaxCall('jinxapi/model/blog/table', 'GET', {
			  orderBy: {blog_field_redosled: 'ASC'}
		    }, this.ucitanBlog);
	  }
    
	  ucitanBlog(resp){
		    if(resp)
            if(resp.OKERR)
    			this.setState({ blogovi: resp.lines, greska:'' });
    		else 
    			this.setState({ greska:[resp.msg]})
        else
            this.setState({ greska:['resp is undefined']});
        this.setSliderChildren(resp.lines);
    }
    setSliderChildren(blogovi){
      let slider = [];
      for(let i=0;i<3;i++){
        if(! this.state.blogovi[i]) continue;
        let blog = this.state.blogovi[i];
        slider.push(                
            <div className="slider1_sadrzaj" style={ { 
                backgroundImage:"url('img/blog/"+blog.slika+"')"
              }}  key={i}>
              <div className="slider1_maska">
              <h1 className="text-center text-light display-4 ">
                {/* <a href={"/blog/"+blog.id}> */}
                  {blog.naslov}
                  {/* </a> */}
                  </h1>
                <hr />
                <br />
                <h3 className="text-center text-light mt-1 lead"><p>{blog.kraci_text}<br/><a href={"/blog/"+blog.id}>pročitaj dalje...</a></p></h3>
              </div>
            </div>
        );
    }
        this.setState({ slider: slider});
    }
	  render(){

        let blogs = [[],[],[]];
        for(let i=0; i<this.state.blogovi.length; i++){
            if(!this.state.blogovi[i]) continue;
            let blog = this.state.blogovi[i];

            let slika = (
                <div></div>
            );
            //let kl = (i<4)? '' : ' hc-element-invisible';
            let attr = (i<4)? {} : {
                    'data-animation-effect':"fadeInUpSmall", 
                    'data-effect-delay':"100"};
            if (blog.slika.trim() !== ''){
                slika = (
                    <div className="overlay-container">

                        <img src={"img/blog/"+blog.slika} alt={blog.naslov} />

                        <a className="overlay-link" href={"/blog/"+blog.id}><i className="fa fa-link"></i></a>

                    </div>
                )
            }


            let a = (

               

                 
                  <article className="blogpost hc-shadow-2 light-gray-bg bordered" {...attr}>

                    {slika}
                    
                    <header>

                      <h2><a href={"/blog/"+blog.id}>{blog.naslov}</a></h2>
                      {}

                    </header>

                    <div className="blogpost-content">

                      <p>{blog.kraci_text}</p>

                    </div>

                    <footer className="clearfix">

                      <div className="link pull-right"><i className="fa fa-link pr-1"></i><a href={"/blog/"+blog.id}>Pročitaj Više</a></div>

                    </footer>

                  </article>
            );

            // blogs[i%3].push(<div key={blog.id} className="masonry-grid-item">
            //     {a}
            //     </div>);

            blogs[0].push(
              <div key={blog.id} className={"masonry-grid-item "+((i%2===0)? 'd-md-none ' : '')+((i%3==0)? 'd-lg-block' : 'd-lg-none')}>
                {a}
              </div>
            );
            blogs[1].push(
              <div key={blog.id} className={"masonry-grid-item "+((i%2===1)? 'd-md-none ' : '')+((i%3===1)? 'd-lg-block' : 'd-lg-none')}>
                {a}
              </div>
            );
            blogs[2].push(
              <div key={blog.id} className={"masonry-grid-item d-none"+((i%3===2)? 'd-lg-block ' : '')}>
                {a}
              </div>
            );
        }
        let naslov = (
          


          <div className="container-fluid">

          <div className="row justify-content-lg-center">

            <div className="col-lg-8 text-center pv-20">

              <br />

              <br />

              <h1 className="page-title text-dark text-center display-3 m-3 p-3">Blog </h1>

              
            </div>

          </div>

        </div>
        );


        
      

        return(
            <>
            {naslov}

              <div className="banner">
              <div className="container clearfix blogs-sliderjx">

                <h1 className="text-center display-3 mt-3 mb-3 najn_vesti">Najnovije vesti</h1>
                  <SliderJx sliderChange="blink" intervalTime={0}>
                      {this.state.slider}
                  </SliderJx>
              </div>
              </div>



              <div id="page-start"></div>

              <section className="main-container col-12">

                <div className="container col-12">
                <br />

                <br />

                <div className="separator"></div>

                <br />

                <br />

                  <div className="main row">
                <div className="main col-md-6 col-lg-4">{blogs[0]}</div>
                <div className="main d-none d-md-block col-md-6 col-lg-4">{blogs[1]}</div>
                <div className="main d-none d-lg-block col-lg-4">{blogs[2]}</div>
                </div>
                </div>
                </section> 
            </>
		);
	}
}

export default Blog;
