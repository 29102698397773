import React, { Component } from 'react';
import { ajaxCall } from '../ajaxCall.js';

class ProizvodSlicni extends Component {
    constructor() {
        super();
        this.state = {proizvod:{} };
        this.ucitanProizvod = this.ucitanProizvod.bind(this);
    }

    componentDidMount() {
        let id = this.props.proizvodId;
        ajaxCall('jinxapi/model/proizvodi/:' + [id], 'GET', {}, this.ucitanProizvod);

    }
    ucitanProizvod(resp) {
       ;//console.log(resp);
        if (resp)
            if (resp.OKERR)
                this.setState({ proizvod: resp.lines[0], greska: '' });
            else
                this.setState({ greska: resp.msg })
        else
            this.setState({ greska: 'greska. resp from server: ' + JSON.stringify(resp) });
    }


    render() {

            return (

                    <aside className="col-lg-4 col-xl-3 ml-xl-auto">

                    <div className="sidebar">

                        <div className="block clearfix">

                        <h3 className="title">Slicni Proizvodi</h3>

                        <div className="separator-2"></div>

                        <div className="media margin-clear">

                            <div className="d-flex pr-2">

                            <div className="overlay-container">

                                <img className="media-object border " src="img/stolice/5.jpg" alt="blog-thumb" />

                                <a href="proizvod/1" className="overlay-link small"><i className="fa fa-link"></i></a>

                            </div>

                            </div>

                            <div className="media-body">

                            <h6 className="media-heading"><a href="proizvod/1">Lorem ipsum dolor sit amet</a></h6>



                            </div>

                        </div>

                        <hr />

                        <div className="media margin-clear">

                            <div className="d-flex pr-2">

                            <div className="overlay-container">

                                <img className="media-object border " src="img/stolice/6.jpg" alt="blog-thumb" />

                                <a href="proizvod/1" className="overlay-link small"><i className="fa fa-link"></i></a>

                            </div>

                            </div>

                            <div className="media-body">

                            <h6 className="media-heading"><a href="proizvod/1">Eum repudiandae ipsam</a></h6>



                            </div>

                        </div>

                        <hr />

                        <div className="media margin-clear">

                            <div className="d-flex pr-2">

                            <div className="overlay-container">

                                <img className="media-object border" src="img/stolice/7.jpg" alt="blog-thumb" />

                                <a href="proizvod/1" className="overlay-link small"><i className="fa fa-link"></i></a>

                            </div>

                            </div>

                            <div className="media-body">

                            <h6 className="media-heading"><a href="proizvod/1">Quia aperiam velit fuga</a></h6>



                            </div>

                        </div>

                        <hr />

                        <div className="media margin-clear">

                            <div className="d-flex pr-2">

                            <div className="overlay-container">

                                <img className="media-object border " src="img/stolice/8.jpg" alt="blog-thumb" />

                                <a href="proizvod/1" className="overlay-link small"><i className="fa fa-link"></i></a>

                            </div>

                            </div>

                            <div className="media-body">

                            <h6 className="media-heading"><a href="proizvod/1">Fugit non natus officiis</a></h6>



                            </div>

                        </div>

                        </div>

                    </div>

                    </aside>
            );
    }

}

export default ProizvodSlicni;