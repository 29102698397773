import React, {Component} from 'react';
import {ajaxCall} from '../ajaxCall.js';

class Najprodavaniji extends Component{
	constructor(){
		super();
		this.state = { slike: [], greska:''  };
		this.ucitaneSlike = this.ucitaneSlike.bind(this);
	}
//"SELECT `proizvodi_slike`.`id`, `proizvodi_slike`.`proizvod_id`, `proizvodi_slike`.`tip`, `proizvodi_slike`.`slika_m`, `proizvodi_slike`.`slika_v`, `proizvodi_slike`.`redosled` FROM `proizvodi_slike` ORDER BY proizvodi_slike.redosled ASC"
	componentDidMount(){
		ajaxCall('jinxapi/model/proizvodi_slike/table', 'GET', {
            join:['proizvodi'],
            selCols:['proizvodi_field_Id', 'proizvodi_slike_field_slika_m', 'proizvodi_slike_field_slika_v'],
            whereCols:[
                {colName:'proizvodi_field_Najprodavaniji', colVal:1, oper:'=', logicOper:'AND'},
                {colName:'proizvodi_slike_field_redosled', colVal:1, oper:'=', logicOper:'AND'}
            ],
			orderBy: {proizvodi_slike_field_proizvod_id: 'ASC'}
        }, this.ucitaneSlike);
	}
	ucitaneSlike(resp){
		if(resp)
            if(resp.OKERR)
                this.setState({ slike: resp.lines, greska:'' });
            else 
                this.setState({ greska:[resp.msg]})
        else{
            this.setState({ greska:['resp ne postoji']});
        }

	}
	render(){
        let slike = [];
        let slikePo4 = [];
        let slikePo1 = [];
        // let a;
		if(this.state.greska !== '')
			slike = this.state.greska;
		else
			if(this.state.slike.length > 0){
				    slike = this.state.slike.map(slika => {
                            return(
                                <div className="col-md-3 mb-3 " key={slika.proizvodi_field_Id} >
                                    <div className="card border1 shadow-lg">
                                        <a href={"proizvod/"+slika.proizvodi_field_Id}>
                                            <div className="img-fluid slika hc-shadow-2" style={{backgroundImage:"url('img/stolice/"+ slika.proizvodi_slike_field_slika_m +"')"}}></div>
                                        </a>
                                    </div>
                                </div>
                            )
                    });
				    for(let i=0; i<slike.length; i++){
                        let a = (i == 0)? 'carousel-item active' : 'carousel-item';
                        slikePo1.push( 
                            <div className={a} key={"gr1"+this.state.slike[i].proizvodi_field_Id}>
                             {slike[i]}
                            </div>
                        );
                    }
                    
                    
                    for(let i=0; i<slike.length; i+=4){
                        let a = (i == 0)? 'carousel-item active' : 'carousel-item';
                        slikePo4.push( 
                            <div className={a} key={"gr4"+this.state.slike[i].proizvodi_field_Id}>
                             {slike[i]}{slike[i+1]}{slike[i+2]}{slike[i+3]}
                            </div>
                        );
                    }
            }
		return (
            <section id="najprodavaniji" className="pv-30">
            <div id="multi-item-example-4" className="d-none d-md-block carousel slide carousel-multi-item carousel-multi-item-2" data-ride="carousel">
                <div className="controls-top text-center my-md-3 text-dark">
                    <div>
                        <h2 className="title"><span className="text-muted">NAJPRODAVANIJI</span> <strong>PROIZVODI</strong></h2>
                        <div className="separator"></div>
                    </div>
                    <a className="text-dark" href="#multi-item-example-4" data-slide="prev"><i className="fa fa-angle-left fa-3x pr-3"></i></a>
                    <a className="text-dark" href="#multi-item-example-4" data-slide="next"><i className="fa fa-angle-right fa-3x pl-3"></i></a>
                </div>
                <div className="carousel-inner mb-3" role="listbox">
                        {slikePo4}
                </div>
            </div>
            <div id="multi-item-example-1" className="d-md-none carousel slide carousel-multi-item carousel-multi-item-2" data-ride="carousel">
                <div className="controls-top text-center my-md-3 text-dark">
                    <div>
                        <h2 className="title"><span className="text-muted">NAJPRODAVANIJI</span> <strong>PROIZVODI</strong></h2>
                        <div className="separator"></div>
                    </div>
                    <a className="text-dark" href="#multi-item-example-1" data-slide="prev"><i className="fa fa-angle-left fa-3x pr-3"></i></a>
                    <a className="text-dark" href="#multi-item-example-1" data-slide="next"><i className="fa fa-angle-right fa-3x pl-3"></i></a>
                </div>
                <div className="carousel-inner mb-3" role="listbox">
                        {slikePo1}
                </div>
            </div>
            </section>
		);
	}
}

export default Najprodavaniji;
