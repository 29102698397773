import React, { Component } from 'react';
import { ajaxCall } from './ajaxCall.js';
import ModalWindow from './BigImg/ModalWindow';


class Registracija extends Component {
    constructor(props) {
        super(props);
        this.state = { podaci: [], greska: '',saljiDisabled:false,
        modalContent:null, modalCloseFunction:'modalClose', modalBtns:'', modalTitle:null };
        this.refShow = React.createRef();
        this.registracija = this.registracija.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.modalCloseReg = this.modalCloseReg.bind(this);

        this.input1 = React.createRef();
        this.input2 = React.createRef();
        this.input3 = React.createRef();
        this.input4 = React.createRef();
        this.input5 = React.createRef();
        this.input6 = React.createRef();
        this.input7 = React.createRef();
        this.input8 = React.createRef();
        this.input9 = React.createRef();
    }


    componentDidMount() {
        this.refShow.current.classList.add('hc-element-visible');
    }
    modalClose(){
        this.setState({ modalContent:null,    modalCloseFunction:'modalClose',  modalBtns:'', modalTitle:null });
        this.setState({saljiDisabled:false});
    }
    modalCloseReg(){
        this.setState({ modalContent:null,    modalCloseFunction:'modalClose',  modalBtns:'', modalTitle:null });
        window.location.href = "/";
    }
    registracija(){
        this.setState({saljiDisabled:true});
        let vals = [];
        for(let i=1; i<=9; i++){
            vals[i] = this['input'+i].current.value.trim();
            if(vals[i] == ''){
                this.setState({modalContent:'Sva polja moraju biti popunjena.' });
                return;
            }
        }
        //proveri
        //check email
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (! re.test(this.input7.current.value) ) {
            this.setState({modalContent:'Greška! Niste dobro upisali email.' });
            return;
        }
        //check passwords
        if (this.input8.current.value !== this.input8.current.value ) {
            this.setState({modalContent:'Greška! Lozinke se ne poklapaju.' });
            return;
        }
        //check pib
        if (this.input3.current.value.length !==9 ) {
            this.setState({modalContent:'Greška! PIB mora imati 9 cifara.' });
            return;
        }
        //upisi
        let data = { 'returnAffected':true, cols:{}};
        data['cols']['users_field_pib'] = vals[3];
        data['cols']['users_field_naziv'] = vals[1];
        data['cols']['users_field_adresa'] = vals[2];
        data['cols']['users_field_maticni_broj'] = vals[4];
        data['cols']['users_field_telefon'] = vals[6];
        data['cols']['users_field_kontakt'] = vals[5];
        data['cols']['users_field_email'] = vals[7];
        data['cols']['users_field_password'] = vals[8];
        ajaxCall('jinxapi/model/users/input', 'POST', data, (resp)=>{
            if(resp.OKERR == true){
                this.setState({modalContent:'Vaša registracija je prosleđena. Nakon odobrenja, dobićete mail			potvrde i mogućnost poručivanja putem sajta.' , modalCloseFunction:'modalCloseReg' });
            }else{
               ;//console.log("RESP REG: ", resp)
                //"Error 1 in sql query: Duplicate entry '123456789' for key 'pib' - INSERT INTO `users`(`pib`, `naziv`, `adresa`, `maticni_broj`, `telefon`, `kontakt`, `email`, `password`) VALUES ('123456789', 'w', 'w', 'w', 'w', 'w', 'w@w.com', 'w')"
                if(resp.msg.indexOf('Duplicate') > 0){
                    let a1 = resp.msg.indexOf("'");
                    let b1 = resp.msg.indexOf("'", a1+1);
                    let c1 = resp.msg.substring(a1+1,b1);
                    
                    let a = resp.msg.indexOf("'", resp.msg.indexOf('for key '));
                    let b = resp.msg.indexOf("'", a+1);
                    let c = resp.msg.substring(a+1,b);
                    this.setState({modalContent:'Već imamo registrovanog korisnika koji u polje '+c+' ima upisano '+c1});

                }else
                    this.setState({modalContent:'Nesto nije u redu. Niste poslali prijavu.'});
            }
        });
    }

    render() {

        let podaci = [];

        if (this.state.greska !== '')
            podaci = this.state.greska;
        else
            if (this.state.podaci.length > 0) {
               ;//console.log(this.state.podaci);
            }
        return (
        <div className="main-container main-container-reg">
                <ModalWindow onClose={this[this.state.modalCloseFunction]} btns={this.state.modalBtns} title={this.state.modalTitle}>
                    {this.state.modalContent} 
                </ModalWindow>
        <br />

        <br />

        <div className="container">

            <div className="row justify-content-center">

                <div className="col-auto">
                    
                    

                    <div className="main hc-element-invisible" ref={this.refShow} >

                        <div className="form-block p-30 bg-light">

                            <br />

                            <h2 className="title display-4 text-center">Registracija</h2>

                            <br />

                            <div className="form-horizontal">

                                <div className="form-group has-feedback row">

                                    <label for="inputName" className="col-md-3 control-label text-md-right col-form-label">Ime firme <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input1} type="text" className="form-control" id="inputName" required />

                                        <i className="fa fa-pencil form-control-feedback pr-4"></i>

                                    </div>

                                </div>

                                <div className="form-group has-feedback row">

                                    <label for="inputLastName" className="col-md-3 control-label text-md-right col-form-label">Adresa <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input2} type="text" className="form-control" id="inputLastName" required />

                                        <i className="fa fa-pencil form-control-feedback pr-4"></i>

                                    </div>

                                </div>

                                <div className="form-group has-feedback row">

                                    <label for="inputUserName" className="col-md-3 control-label text-md-right col-form-label">PIB <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input3} type="text" className="form-control" id="inputUserName" required />

                                        <i className="fa fa-file form-control-feedback pr-4"></i>

                                    </div>

                                </div>

                                <div className="form-group has-feedback row">

                                    <label for="inputUserName" className="col-md-3 control-label text-md-right col-form-label">Matični broj <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input4} type="text" className="form-control" id="inputUserName" required />

                                        <i className="fa fa-file form-control-feedback pr-4"></i>

                                    </div>

                                </div>



                                <div className="form-group has-feedback row">

                                    <label for="inputUserName" className="col-md-3 control-label text-md-right col-form-label">Kontakt Osoba <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input5} type="text" className="form-control" id="inputUserName" required />

                                        <i className="fa fa-user form-control-feedback pr-4"></i>

                                    </div>

                                </div>



                                <div className="form-group has-feedback row">

                                    <label for="inputUserName" className="col-md-3 control-label text-md-right col-form-label">Telefoni <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input6} type="text" className="form-control" id="inputUserName" required />

                                        <i className="fa fa-phone form-control-feedback pr-4"></i>

                                    </div>

                                </div>

                                <div className="form-group has-feedback row">

                                    <label for="inputEmail" className="col-md-3 control-label text-md-right col-form-label">Email <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input7} type="email" className="form-control" id="inputEmail" required />

                                        <i className="fa fa-envelope form-control-feedback pr-4"></i>

                                    </div>

                                </div>

                                <div className="form-group has-feedback row mb-0">

                                    <label for="inputPassword" className="col-md-3 control-label text-md-right col-form-label">Lozinka <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input8} type="password" className="form-control" id="inputPassword" required />

                                        <i className="fa fa-lock form-control-feedback pr-4"></i>

                                       

                                    </div>

                                </div>

                                <div className="form-group has-feedback row mt-0">

                                    <label for="inputPassword" className="col-md-3 control-label text-md-right col-form-label">Potvrdi Lozinku <span className="text-danger small">*</span></label>

                                    <div className="col-md-8">

                                        <input ref={this.input9} type="password" className="form-control" id="inputPassword" required />

                                        <i className="fa fa-lock form-control-feedback pr-4"></i>

                                    </div>

                                </div>
                                                   
                                <div className="form-group row">

                                    <div className="ml-md-auto col-md-9">

                                        <button type="submit" className="btn btn-group btn-default btn-animated" onClick={this.registracija} disabled={this.state.saljiDisabled}>Otvori nalog <i className="fa fa-check"></i></button>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    

                </div>

            </div>

        </div>

    </div>
        );
    }

}

export default Registracija;