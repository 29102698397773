import React, { useState, useEffect } from 'react';
import './SliderJx.css'; 

function SliderJx(props){
    //console.log('props.children', props.children);
    const [ind, setInd] = useState(0);
    const [children, setChildren] = useState([]);
    const defaultSliderChange = 'ltr';

    const glavnaKlasa = 'sliderjx-'+((props.sliderChange)? props.sliderChange : defaultSliderChange);
    const maxInd = props.children.length;
    const leftArrow = (props.LeftArrow)?? (<span>&#xab;</span>);
    const rightArrow = (props.RightArrow)?? (<span>&#xbb;</span>);

    //const [nextTimeout, setNextTimeout] = useState(); 
    let nextTimeout;
    const intTime = (props.intervalTime)?? 4000;
    useEffect(()=>{
        changeImage(0);
    }, [props.children]);

    useEffect(()=>{
        if(intTime == 0) return;
        if(props.children.length <2) return;
        clearTimeout(nextTimeout);        
        nextTimeout = setTimeout(()=>{            
            changeImage(1)
        }, intTime);
        return () => clearTimeout(nextTimeout);
    }, [props.children, ind]);

    function changeImage(p){     
        //console.log('changeImage p', p)      
        let nInd = ind+p;
        if(nInd >= maxInd) nInd = 0;
        if(nInd < 0) nInd = maxInd-1;
        let nChildren;

        if(glavnaKlasa == 'sliderjx-blink')
            nChildren = props.children.map((child, i) => 
                <div className={"sliderjx-child"
                    + ((i == nInd)? ' sliderjx-child-active' : '')} 
                key={i}>{child}</div>
            );
        
        if(glavnaKlasa == 'sliderjx-ltr'){
            let preInd = nInd-1;
            if(preInd < 0) preInd = maxInd - 1;
            
            let cl;
            if(p>0) cl = "left";
            if(p<0) cl = "right";
            nChildren = props.children.map((child,i) => (
                <div  
                    className={ "sliderjx-child" 
                    + ((i==nInd)? ' sliderjx-child-active' : '')
                    + ((i==ind && p!=0)? ' sliderjx-child-move-'+cl : '')
                    + ((i==preInd)? ' sliderjx-child-pre' : '')} 
                    key={i}>{child}</div>
                ));
        }
        setChildren(nChildren);
        setInd(nInd);

    }


    return (
        <div className={"sliderjx-parent "+glavnaKlasa}>
            <div className="sliderjx-allchildren">
                {children}
            </div>
            {(props.children.length > 1)? <>
            <div className="sliderjx-arrow sliderjx-arrow-left" onClick={()=>changeImage(-1)}>{leftArrow}</div>
            <div className="sliderjx-arrow sliderjx-arrow-right" onClick={()=>changeImage(1)}>{rightArrow}</div>
            </> : <></>}
        </div>
    )
}

export default SliderJx;