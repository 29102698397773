import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';


class MenuGrupe extends Component {
	constructor(props){
		super(props);
		this.state = { grupePodaci: [], greska:''  };
		this.ucitaniGrupePodaci = this.ucitaniGrupePodaci.bind(this);
	}
	ucitaniGrupePodaci(resp){
		if(resp)
            if(resp.OKERR)
                this.setState({ grupePodaci: resp.lines, greska:'' });
            else 
                this.setState({ greska:[resp.msg]})
        else{
            this.setState({ greska:['resp ne postoji']});
        }

	}
	componentDidMount(){
		ajaxCall('jinxapi/model/grupe/table', 'GET', {
			orderBy: {grupe_field_redosled: 'ASC'}
		}, this.ucitaniGrupePodaci);
	}
	render() {
        let id = (this.props.idGrupe)? this.props.idGrupe : 0;
		let grupePodaci = [];
		if(this.state.greska !== '')
               grupePodaci = this.state.greska;
		else
			if(this.state.grupePodaci.length > 0)
               grupePodaci = this.state.grupePodaci.map(grupaPodatak => {
				   	let fa = (grupaPodatak.id !== id)? "fa-bars" : '';
                    return(
                         <li key={grupaPodatak.id}><a href={"/grupa/"+grupaPodatak.id} ><i className={"fa pr-2 "+fa}></i>{grupaPodatak.naziv}</a></li>
                    )
               })
	    return (
           <ul className="menu">
               {grupePodaci}
           </ul>
	    );
	}
}

export default MenuGrupe;

