import React, { Component } from 'react';
import { ajaxCall } from './ajaxCall.js';


class Grupa extends Component {
    constructor(props) {
        super(props);
        this.state = { proizvodi: [], greska: '', naziv:'' };
        this.ucitaniProizvodi = this.ucitaniProizvodi.bind(this);
        this.ucitanNaslov = this.ucitanNaslov.bind(this);

    }
    ucitaniProizvodi(resp) {

        if (resp)
            if (resp.OKERR)
                this.setState({ proizvodi: resp.lines, greska: '' });
            else
                this.setState({ greska: [resp.msg] })
        else {
            this.setState({ greska: ['resp ne postoji'] });

        }
    }
    ucitanNaslov(resp) {

        if (resp)
            if (resp.OKERR)
                this.setState({ naziv: resp.lines[0].naziv, greska: '' });
            else
                this.setState({ greska: [resp.msg] })
        else {
            this.setState({ greska: ['resp ne postoji'] });

        }
    }

    componentDidMount() {
        //this.setState({id: this.props.match.params.id});
        let id = this.props.match.params.id;

        //let it here for future generations
        // var that = this;
        // ajaxCall('jinxapi/model/proizvodi_grupe/table', 'GET', {
        // 	whereCols: [ { colName: 'proizvodi_grupe_field_grupa_id', colVal: id, oper:'=', logicOper: 'AND' }]
        // }, function(resp){
        //           let str1 = resp.lines.reduce((s, el)=>s+el.proizvod_id+",", "(");
        //          ;//console.log(str1);
        //           let str = str1.substring(0, str1.length-1)+")";
        //          ;//console.log(str);
        //           ajaxCall('jinxapi/model/proizvodi/table', 'GET', {
        //               whereCols: [ { colName: 'proizvodi_field_Id', colVal: str, oper:'IN', logicOper: 'AND' }]
        //           }, that.ucitaniProizvodi);
        //       });
        var data = { sqlQuery: 'pr_slika1', whereCols: [id] };
        ajaxCall('jinxapi/sqlParamQuery', 'GET', data, this.ucitaniProizvodi);
        ajaxCall('jinxapi/model/grupe/:'+id, 'GET', {}, this.ucitanNaslov);
    }
    render() {
        let proizvodi = [];

        if (this.state.greska !== '')
            proizvodi = this.state.greska;
        else
            if (this.state.proizvodi.length > 0) {
               ;//console.log(this.state);
                proizvodi = this.state.proizvodi.map(pr => {
                    let a = "img/stolice/" + pr.slika;
                    let b = pr.naziv;
                    let c = pr.id;
                    let d = "proizvod/" + pr.id;
                    return (

                        <div key={c} className=" col-sm-6 col-md-4 col-lg-3 mx-5 mx-sm-2 d-flex justify-content-center news-card">
                            <a href={d} className="news-card__card-link"></a>
                            <div className="poz_all divslika news-card__image" style={{backgroundImage:"url('"+a+"')"}}></div>
                            {/* <img src={a} alt="" className="news-card__image" /> */}
                            <div className="news-card__text-wrapper ">
                                <h2 className="news-card__title">{b}</h2>
                                <div className="news-card__post-date"></div>
                                <div className="news-card__details-wrapper">
                                    {/* <p className="news-card__excerpt"></p> */}
                                    <a href={d} className="news-card__read-more">Detaljnije <i className="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>
                        </div>
                    )
                });
            }
        return (
            <div className="container-fluid content-wrapper ">
                <h2 className="col-12">{this.state.naziv}</h2>
                {proizvodi}
            </div>
        );
    }
}

export default Grupa;

//select Naziv, PS.slika_m from (proizvodi AS P LEFT JOIN proizvodi_grupe AS PG ON P.id=PG.proizvod_id) LEFT JOIN proizvodi_slike AS PS ON P.id=PS.proizvod_id where PG.grupa_id=4

//select Naziv, PS.slika_m from (proizvodi AS P LEFT JOIN proizvodi_grupe AS PG ON P.id=PG.proizvod_id) LEFT JOIN proizvodi_slike AS PS ON P.id=PS.proizvod_id where PG.grupa_id=4 group by Naziv