import React, { useState, useEffect } from 'react';
import './BigImg.css'; 
import closeImg from './xred.png';

function BigImgDiv(props){
    const [closed, setClosed] = useState(true);
    const [bckgImg, setBckgImg] = useState(props.bckgImg);

    const [children, setChildrenClick] = useState([]);

    useEffect(()=>{
        let newCh = React.cloneElement(props.children, { onClick: ()=>{setClosed(false)}  } );
        setChildrenClick(newCh);
        setBckgImg(props.bckgImg);
    }, [props.children, props.bckgImg]);

    function sadrzajOnClick(e){
        e.stopPropagation();
    }
    function closeOnClick(e){
        setClosed(true);
    }

    return (
        <div>
            {children}
            <div className={"md_wrapper"+((closed)? ' closed' : '')}  onClick={closeOnClick}>
                <div className="md_close"><img src={closeImg} onClick={closeOnClick} /></div>
                <div className="md_sadrzaj" onClick={sadrzajOnClick}  style={{ backgroundImage: 'url("'+bckgImg+'")' }} ></div>
            </div>
        </div>
    );
}

export default BigImgDiv;