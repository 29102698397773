import React, {Fragment, Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import parse from 'html-react-parser';


class Onama extends Component {
	constructor(){
		super();
		this.state = { podaciOnama: [], greska:''  };
		this.ucitaniPodaciOnama = this.ucitaniPodaciOnama.bind(this);
	}
	ucitaniPodaciOnama(resp){
		if(resp)
            if(resp.OKERR)
                this.setState({ podaciOnama: resp.lines, greska:'' });
            else 
                this.setState({ greska:[resp.msg]})
        else{
            this.setState({ greska:['resp ne postoji']});
        }

	}
	componentDidMount(){
        ajaxCall('jinxapi/model/onama/table', 'GET', {}, this.ucitaniPodaciOnama);;
    }
    
    nl2br(txt){
        return txt.split('\n').join('<br />');
    }
	render() {

        let podaciOnama;
        if(this.state.podaciOnama.length > 0){
                podaciOnama = this.state.podaciOnama.map(podatakOnama => 
                <>
                <section className="main-container padding-bottom-clear onama_tekst" key={podatakOnama.id}>
                    <div className="container">
                        <div className="row">
                            <div className="main col-12">
                                <div className="row">
                                    {
                                    (podatakOnama.id % 2 ==1)? (
                                        <>
                                        <div className="col-lg-6">
                                        <p>{parse(podatakOnama.tekst)}</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="overlay-container overlay-visible">
                                            <img src={"img/onama/"+podatakOnama.slika} alt="onama" />
                                        </div>
                                    </div>
                                    </>
                                    ) : (
                                        <>
                                        <div className="col-lg-6">
                                        <div className="overlay-container overlay-visible">
                                            <img src={"img/onama/"+podatakOnama.slika} alt="onama" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                    <p>{parse(podatakOnama.tekst)}</p>
                                </div>
                                        </>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </section><br/><br/><br/>
                <div className="separator-2"></div>
                </>
                );
        }else{
            podaciOnama = "Stranica nije dobro učitana."
        }
                
	    return (
			<>
                <div className="container-fixed text-light bg-dark">
                    <div className="row justify-content-lg-center">
                        <div className="col-lg-8 text-center pv-20">
                            <br/>
                            <h3 className="display-4 text-light" data-animation-effect="fadeIn" data-effect-delay="100">Želite kancelariju koja bi Vas inspirisala?</h3>
                            <div className="separator mt-10" data-animation-effect="fadeIn" data-effect-delay="100"></div>
                            <h3 className="display-5 text-light text-center" data-animation-effect="fadeIn" data-effect-delay="100">Prepustite to nama!</h3>
                            <br/>
                        </div>
                    </div>
                </div>

                

                <br/>
                <br/>

                {podaciOnama}

                <br/><br/>
			</>
	    );
	}
}

export default Onama;
