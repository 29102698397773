import React, {Component} from 'react';
import MenuGrupe from './MenuGrupe';



class Menu extends Component {
  render(){
    return (
     <>
        <div className="scrollToTop bg-black circle text-uppercase font-weight-bold"><i className="fa fa-angle-up"></i></div>
        <div className="page-wrapper bg-black text-uppercase">
            <div className="header-container bg-black">
                <header className="header  dark fixed fixed-desktop clearfix bg-black">
                    <div className="container bg-black">
                        <div className="row bg-black justify-content-around">
                            <div className="col-lg-10 ">
                                <div className="header-second clearfix">
                                    <div className="main-navigation main-navigation--mega-menu  animated">
                                        <nav className="navbar navbar-expand-lg navbar-light p-0">
                                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-1" aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <div className="navbar-brand clearfix hidden-lg-up">
                                                <div id="logo-mobile" className="logo border border-white mb-1">
                                                    <a href="/"><img id="logo-img-mobile" className="logo_img shadow-lg" src="img/logo3.jpg" alt="The Project" /></a>
                                                </div>
                                            </div>
                                            <div className="collapse navbar-collapse" id="navbar-collapse-1">
                                                <ul className="navbar-nav ml-xl-5">
                                                    <li className="nav-item dropdown  mega-menu mega-menu--wide">
                                                        <a href="/" className="nav-link " id="first-dropdown" aria-haspopup="true" aria-expanded="false">Home</a>
                                                    </li>
                                                    <li className="nav-item  mega-menu mega-menu--wide">
                                                        <a href="/brendovi" className="nav-link" id="second-dropdown" aria-haspopup="true" aria-expanded="false">Brendovi</a>
                                                    </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/katalozi" className="nav-link" id="third-dropdown" aria-haspopup="true" aria-expanded="false">Katalozi</a>
                                                </li>
                                                <li className="nav-item dropdown  mega-menu mega-menu--narrow">

                                                    <a href="#" className="nav-link dropdown-toggle" id="fourth-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Proizvodi</a>
                                                    <div className="dropdown-menu leftM col-md-6 leftM " aria-labelledby="fourth-dropdown">
                                                                <div className="col-md-12">
                                                                    <h4 className="title"><i className="fa fa-magic pr-2"></i>
                                                                        GRUPE</h4>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="divider"></div>
                                                                            <MenuGrupe />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item dropdown ">
                                                    <a href="/blog" className="nav-link " id="fifth-dropdown" aria-haspopup="true" aria-expanded="false">Blog</a>
                                                </li>
                                                <li className="nav-item dropdown ">
                                                    <a href="/onama" className="nav-link " id="sixth-dropdown" aria-haspopup="true" aria-expanded="false">O nama</a>
                                                </li>
                                                <li className="nav-item dropdown ">
                                                    <a href="/kontakt" className="nav-link " id="seventh-dropdown" aria-haspopup="true" aria-expanded="false">Kontakt</a>
                                                </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-auto hidden-md-down pl-3">
                                <div id="logo" className="logo border border-white my-2 ml-3">
                                    <a href="/"><img className="logo_img" id="logo_img" src="img/logo3.jpg" alt="The Project" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
            </header>
        </div>
        <div className="banner clearfix">
        </div>
        <div id="page-start"></div>
        </div>
    </>
    );
  }
}

export default Menu;
