import React, { useState, useEffect } from 'react';
import './BigImg.css'; 
import check from './check.png';
import q from './q.png';
import x from './xred.png';

/*
Sta roditelj mora da ima:
    import ModalWindow from './BigImg/ModalWindow';

    constructor(props) {
        this.state = {   modalContent:null, modalCloseFunction:'modalClose', modalBtns:'', modalTitle:null };
        this.modalClose = this.modalClose.bind(this);
    }
    
    modalClose(){
        this.setState({ modalContent:null,    modalCloseFunction:'modalClose',  modalBtns:'', modalTitle:null });
    }

    <ModalWindow onClose={this[this.state.modalCloseFunction]} btns={this.state.modalBtns} title={this.state.modalTitle}>
        {this.state.modalContent} 
    </ModalWindow>


Primer:
    modalCloseYesNo(vr){
        this.modalClose();
        //URADI NESTO
        // alert((vr==0)? 'Yes' : 'No');
    }
    
POZIV:
    this.setState({
        modalContent:(<p>Moja poruka!</p>), ili "Moja poruka!"
        modalBtns: reci razdvojene razmacima 'YES NO' ili 'Ok Cancel'
        modalTitle:<p style={{backgroundColor:'red'}}>OPAAAA!!!!</p>,
        modalCloseFunction:'modalCloseYesNo' ili defaultna je 'modalClose',
    });
    this.setState({
        modalContent:(<p>Are you sure? We have some long text here. <br />We have some long text here. We have some long text here. We have some long text here. <br />We have some long text here. <br /></p>), 
        modalBtns:'Yes No',
        modalCloseFunction: 'modalCloseYesNo'
    })

    this.setState({modalContent:'Pogresan user i/ili password!' });
    


*/


function ModalWindow(props){
    const defaultBtns = ['OK'];
    const defaultTitle = <p>OBAVEŠTENJE</p>;

    const [btnText, setBtnText] = useState(defaultBtns);
    const [title, setTitle] = useState((props.title)?? defaultTitle);

    useEffect(()=>{
       ;//console.log("props.btns: ", props.btns);
        let btns = (props.btns && props.btns !== '')? props.btns.split(' ') : defaultBtns;
       ;//console.log("btns: ", btns);
        setBtnText(btns);
        setTitle((props.title)?? defaultTitle);
    }, [props.children, props.btns, props.title]);

    function sadrzajOnClick(e){
        e.stopPropagation();
    }
    function closeOnClick(e, vr){
        if(props.onClose && typeof(props.onClose) === 'function') props.onClose(vr);
    }

    return (
            <div className={"md_wrapper info"+(!(props.children)? ' closed' : '')}  onClick={closeOnClick}>
                <div className="md_sadrzaj" onClick={sadrzajOnClick}  style={{ backgroundImage: 'url("'+props.bckgImg+'")' }} >
                    <h3>{title}</h3>
                    
                    <div className="md_uc_wrapper"><img className="check" src= {check} alt="..."/>{props.children}</div>
                    {(btnText.length > 0)? (
                    <div className="md_footer" style={{gridTemplateColumns:'repeat('+btnText.length+', 1fr)'}}>
                    {
                        btnText.map( (txt, i) => (
                            <span key={i} className={"md_button"+((i%2==1)? ' light' :'')} onClick={(e)=>closeOnClick(e,i)}>{txt}</span>
                        ))
                    }
                    </div>
                    ) : ''}
                </div>
            </div>
    );
}

export default ModalWindow;