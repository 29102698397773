import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';


class Brand extends Component {
	constructor(){
		super();
		this.state = { brendovi: [], greska:''  };
		this.ucitaniBrendovi = this.ucitaniBrendovi.bind(this);
	}
	ucitaniBrendovi(resp){
		if(resp)
            if(resp.OKERR)
    			this.setState({ brendovi: resp.lines, greska:'' });
    		else 
    			this.setState({ greska:[resp.msg]})
        else
            this.setState({ greska:['resp is undefined']});
	}
	componentDidMount(){
		ajaxCall('jinxapi/model/brendovi/table', 'GET', {
			orderBy: {brendovi_field_redosled: 'ASC'}
		}, this.ucitaniBrendovi);
	}
	render() {
		let brendovi = [];
		// let grupePo3 = [];
		// let grupePo3xxx = [];
		if(this.state.greska !== '')
			brendovi = this.state.greska;
		else
			if(this.state.brendovi.length > 0){
                brendovi = this.state.brendovi.map(brend => {
                            return(
                                <div className="col-md-4">
                                    <div className="image-box team-member style-2 hc-shadow-2 bordered light-gray-bg text-center">
                                        <div className="overlay-container overlay-visible">
                                            <div className="slika1" style={{backgroundImage:"url('img/brendovi/"+brend.slika_bck+"')"}}></div>
                                            <div className="overlay-bottom">
                                                <p className="small margin-clear">{brend.tekst}</p>
                                            </div>
                                        </div>
                                        <div className="body">
                                            <h3 className="margin-clear">{brend.naziv}</h3>
                                            <br/>
                                            <a href={brend.web_adresa} className="btn btn-animated btn-dark" target="_blank">Pročitaj Više<i className="fa fa-arrow-right"></i></a>
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            )
                    });

                    // for(let i=0; i<brendovi.length; i+=3){
                    //     grupePo3.push( 
                    //         < >
                    //             {brendovi[i]}{brendovi[i+1]}{brendovi[i+2]}
                    //         </>
                    //     );
                    //     grupePo3xxx.push( 
                    //         < >
                    //             {brendovi[i+3]}{brendovi[i+4]}{brendovi[i+5]}
                    //         </>
                    //     );
                    // }
			}
	    return (
			<>
				<div className="scrollToTop circle"><i className="fa fa-angle-up"></i></div>
				<div className="page-wrapper">
					<section className="main-container">
						<div className="container">
							<div className="row">
								<div className="main col-12">
									<h1 className="page-title display-4">Brendovi</h1>
									<div className="separator-2"></div>
									<br/>
									<br/>
								</div>
								<br/>
								<br/>
								<div className="main col-12">
									<div className="row grid-space-10">
										{/* {grupePo3} */}
                                {brendovi[0]}{brendovi[1]}{brendovi[2]}

									</div>
									<br/>
									<br/>
								</div>
								{/* {grupePo3xxx} */}
                                {brendovi[3]}{brendovi[4]}{brendovi[5]}

							</div>
                            <br/>
							<br/>
						</div>
					</section>
				</div>  
			</>
	    );
	}
}

export default Brand;
