import React from 'react';

export default function TablePodaci2(props){
    return(
        <table className="table d-lg-none">
                <thead className="thead-dark">
                    <tr>
                        <th colspan="2">VAŠI PODACI:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">PIB:</th>
                        <td>{props.user.pib}</td>
                    </tr>
                    <tr>
                        <th scope="row">naziv:</th>
                        <td>{props.user.naziv}</td>
                    </tr>
                    <tr>
                        <th scope="row">adresa:</th>
                        <td>{props.user.adresa}</td>

                    </tr>
                    <tr>
                        <th scope="row">maticni_broj:</th>
                        <td>{props.user.maticni_broj}</td>

                    </tr>
                    <tr>
                        <th scope="row">telefon:</th>
                        <td>{props.user.telefon}</td>
                    </tr>

                    <tr>
                        <th>kontakt:</th>
                        <td>{props.user.kontakt}</td>
                    </tr>

                    <tr>
                        <th scope="row">email:</th>
                        <td>{props.user.email}</td>
                    </tr>


                </tbody>
            </table>
        );
}

