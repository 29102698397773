import React, { useState, useEffect } from 'react';
import './BigImg.css'; 
import closeImg from './xred.png';

function BigImg(props){
    const [closed, setClosed] = useState(props.closed);
    const [bckgImg, setBckgImg] = useState(props.bckgImg);

    useEffect(()=>{
        setClosed(props.closed);
        setBckgImg(props.bckgImg);
    }, [props.closed, props.bckgImg]);
    // function setCl(cl){
    //     setClosed(cl);
    // }
    // function setImg(img){
    //     setBckgImg(img);
    // }
    function sadrzajOnClick(e){
        e.stopPropagation();
    }
    function closeOnClick(e){
        if(props.onClose) props.onClose();
    }

    return (
    
        <div className={"md_wrapper"+((closed)? ' closed' : '')} onClick={closeOnClick}>
            <div className="md_sadrzaj" onClick={sadrzajOnClick}  style={{ backgroundImage: 'url("'+bckgImg+'")' }} >
                <div className="md_close"><img src={closeImg} onClick={closeOnClick} /></div>
                {(props.children)?? ''}
            </div>
        </div>
    );
}

export default BigImg;