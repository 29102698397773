import React, { useEffect, useState } from 'react';
import { promiseAjaxCall } from '../ajaxCall';

export default function SveKorpe(props){
    const [korpe, setKorpe] = useState([]);
    
    useEffect( ()=>{
       ;//console.log(props.userId);
        ( async () =>{

            let id = props.userId;
            if(id>0){
                let resp = await promiseAjaxCall('jinxapi/model/korpe/table', 'GET', 
                    { whereCols:[{ colName: 'korpe_field_user_id', colVal: id, 
                    oper:'=', logicOper: 'AND'  }]});
                if (resp.OKERR)
                    setKorpe(resp.lines);
                else
                    setKorpe([]);
            }
        })();
    }, [props.userId]);



    return(
        <div className="row">


            <table class="table d-none d-lg-block border-0">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="row" colspan="6" class="px-5 py-2">PREGLED NARUDŽBENICA:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="px-5 py-3">Broj profakture:</th>
                                <th className="px-5 py-3">Datum porudzbine:</th>
                                <th className="px-5 py-3">Cena:</th>
                                <th className="px-5 py-3">Status:</th>
                                <th className="px-5 py-3">Rok isporuke / datum isporuke:</th>
                                <th className="px-5 py-3">Detaljnije</th>
                            </tr>
                            {
                                korpe.map(red => (
                                    <tr>
                                        <td className="px-5 py-3">{red.profaktura}</td>
                                        <td className="px-5 py-3">{red.datum}</td>
                                        <td className="px-5 py-3">{red.cena}</td>
                                        <td className="px-5 py-3">{red.status}</td>
                                        <td className="px-5 py-3">{(red.datum_isporuke !== null)? red.datum_isporuke : red.rok_isporuke }</td>
                                        <td className="px-5 py-3"><a href={"korpa/"+red.id}>Detaljnije</a></td>
                                    </tr>
                                ))
                            }      

                        </tbody>
                    </table>

                    <table className="table col-12 d-lg-none">
                        <thead className="thead-dark">
                            <tr>
                                <th>PREGLED NARUDŽBENICA:</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                                korpe.map(red => (
                                    <>
                            <tr>
                                <th scope="row">Broj fakture:</th>
                                <td>{red.profaktura}</td>
                            </tr>
                            <tr>
                                <th scope="row">Datum porudzbine:</th>
                                <td>{red.datum}</td>

                            </tr>
                            <tr>
                                <th scope="row">Cena:</th>
                                <td>{red.cena}</td>

                            </tr>
                            <tr>
                                <th scope="row">Status:</th>
                                <td>{red.status}</td>

                            </tr>
                            <tr>
                                <th scope="row">rok isporuke / datum isporuke:</th>
                                <td>{
                                    (red.datum_isporuke !== null)? red.datum_isporuke : 
                                        ((red.rok_isporuke !== null)? red.rok_isporuke : ' / ') }</td>
                            </tr>
                            <tr style={{ borderBottom:"2px solid #333"}}>
                                <td>Detaljnije</td>
                                <td><a href={"korpa/"+red.id}>Detaljnije</a></td>
                            </tr>
                            </>
                                ))
                        }
                        </tbody>
                    </table>


                </div>
    );
}

