import React, { Component } from 'react';
import { ajaxCall } from '../ajaxCall.js';



class ProizvodTabs1 extends Component {
    constructor(props) {
        super(props);
       ;//console.log('DODACI IZ CONTRU: ', this.props.dodaci);
        //delovi su sta sve postoji sa podacima - ucitano iz baze direktno
        //selectVrednosti sta je selectovano
        this.state = { pid:this.props.proizvodId, delovi:[], selectVrednosti:this.props.dodaci };
        this.ucitanProizvodOpcije = this.ucitanProizvodOpcije.bind(this);
        this.potvrdiDodatke = this.potvrdiDodatke.bind(this);
        this.changeSelectVal = this.changeSelectVal.bind(this);
        
    }

    componentDidMount() {
        let id = this.props.proizvodId;
        ajaxCall('jinxapi/model/proizvodi_delovi/table', 'GET', {
            join:['delovi'],
            selCols:['delovi_field_id', 'delovi_field_deo', 'delovi_field_naziv', 'delovi_field_slika', 'delovi_field_cena', 'delovi_field_opis', 'proizvodi_delovi_field_cena'],
            whereCols:[
                {colName:'proizvodi_delovi_field_proizvod_id', colVal:id, oper:'=', logicOper:'AND'}
            ],
            orderBy:{'delovi_field_deo': 'ASC'}
        }, this.ucitanProizvodOpcije);
    }
    ucitanProizvodOpcije(resp) {
       ;//console.log(resp);
        if (resp)
            if (resp.OKERR){
                let obj = this.props.dodaci;
                if(this.props.dodaci == ''){
                    obj = {};
                    let deo_tipovi = [...new Set(resp.lines.map(deo =>deo.delovi_field_deo))];
                    for(let deo of deo_tipovi) obj[deo] = {naziv:0, cena:0  };
                }
                this.setState({ delovi: resp.lines, greska: '', selectVrednosti:obj  });
                
            }else
                this.setState({ greska: resp.msg })
        else
            this.setState({ greska: 'greska. resp from server: ' + JSON.stringify(resp) });
    }
    
    changeSelectVal(e, deo_tip){
        let naziv = e.target.value;
        let cena = this.nadjiCenu(deo_tip, naziv);
        let obj = this.state.selectVrednosti;
        let deo_id = e.target.querySelector('option[value="'+naziv+'"]').getAttribute('data-id');
        obj[deo_tip] = { naziv:naziv, cena: cena, id:deo_id };
        this.setState({ selectVrednosti:obj });
        this.props.promenjenaSlika(this.nadjiSliku(deo_tip, naziv), cena);
    }
    nadjiSliku(deo_tip, naziv){
        let pr = this.state.delovi.find(deo => (deo.delovi_field_deo==deo_tip && deo.delovi_field_naziv == naziv));
        if(pr) return pr.delovi_field_slika;
        return '';
    }
    nadjiCenu(deo_tip, naziv){
        let pr = this.state.delovi.find(deo => (deo.delovi_field_deo==deo_tip && deo.delovi_field_naziv == naziv));
        if(pr){
            if(parseFloat(pr.proizvodi_delovi_field_cena) == 0) 
                return pr.delovi_field_cena;
            else
                return parseFloat(pr.proizvodi_delovi_field_cena);
        }
        return 0;
    }
    potvrdiDodatke(){
        //pozovi funkciju i posalji this.state.selectVrednosti
        this.props.potvrdiDodatke(this.state.selectVrednosti);
    }

    render() {
        let deo_tipovi = [...new Set(this.state.delovi.map(deo =>deo.delovi_field_deo))];
        let selectovi = deo_tipovi.map(deo_tip => {
            let delovi = this.state.delovi.filter(deo => deo.delovi_field_deo == deo_tip).map(deo=>(
                <option value={deo.delovi_field_naziv} data-id={deo.delovi_field_id}>{deo.delovi_field_naziv}</option>
            ));
            let arg = (this.state.selectVrednosti[deo_tip])? this.state.selectVrednosti[deo_tip].naziv : 0;
            return (
                <>
                <dt className="col-sm-3 text-sm-right">{deo_tip}: </dt>
                    <dd className="col-sm-9">
                        <select className="custom-select" onChange={(e)=>this.changeSelectVal(e,deo_tip)} 
                            defaultValue={arg} onClick={(e)=>this.changeSelectVal(e,deo_tip)}>

                        <option value="0">Osnovni deo</option>

                        {delovi}
                    </select></dd>
                </>
            )}
        );

            return (


                    <div className="tab-pane active" id="h2tab1">

                        <h4 className="space-top">{(deo_tipovi.length > 0)? "Dodatne Opcije" : "Nema dodatnih opcija"}</h4>

                        <hr />

                        <dl className="row">

                            {selectovi}
                        {(deo_tipovi.length > 0)?
                            <button className="ml-3 btn btn-dark" onClick={this.potvrdiDodatke}>Izmeni</button>
                            :
                            (<></>)
                        }
                        </dl>
                        <hr />

                    </div>

            );
    }

}

export default ProizvodTabs1;